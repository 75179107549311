const events2017 = {
  ABCA: "Western Canada Regional",
  ALHU: "Rocket City Regional",
  ARCHIMEDES: "FIRST Championship - St. Louis - Archimedes Subdivision",
  ARDA: "FIRST Championship - St. Louis - ARDA Division",
  ARLI: "Arkansas Rock City Regional",
  AUSC: "Southern Cross Regional",
  AUSP: "South Pacific Regional",
  AZCMP: "Sanghi Foundation FRC AZ State Championship",
  AZFL: "Arizona North Regional",
  AZPX: "Arizona West Regional",
  BC18: "BattleCry 18",
  CADA: "Sacramento Regional",
  CAIR: "Orange County Regional",
  CALB: "Los Angeles Regional",
  CAMA: "Central Valley Regional",
  CANE: "FIRST Championship - Houston - CANE Division",
  CARSON: "FIRST Championship - St. Louis - Carson Subdivision",
  CARVER: "FIRST Championship - Houston - Carver Subdivision",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  CASJ: "Silicon Valley Regional",
  CATE: "FIRST Championship - St. Louis - CATE Division",
  CAVE: "Ventura Regional",
  CHCMP:
    "FIRST Chesapeake District Championship sponsored by Booz Allen Hamilton",
  CMPMO: "FIRST Championship - St. Louis",
  CMPTX: "FIRST Championship - Houston",
  CODE: "Colorado Regional",
  CTHAR: "NE District - Hartford Event",
  CTTD: "Cow Town ThrowDown",
  CTWAT: "NE District - Waterbury Event",
  CUDA: "FIRST Championship - St. Louis - CUDA Division",
  CURIE: "FIRST Championship - St. Louis - Curie Subdivision",
  DALY: "FIRST Championship - St. Louis - Daly Subdivision",
  DARWIN: "FIRST Championship - St. Louis - Darwin Subdivision",
  EMCC: "East Metro Collaborative Competition",
  FLOR: "Orlando Regional",
  FLWP: "South Florida Regional ",
  FOC: "Festival of Champions",
  FOC17: "Festival of Champions",
  GAALB: "PCH District - Albany Event",
  GACMP: "Peachtree State Championship",
  GACOL: "PCH District - Columbus Event",
  GADAL: "PCH District - Dalton Event",
  GAGAI: "PCH District - Gainesville Event",
  GALILEO: "FIRST Championship - Houston - Galileo Subdivision",
  GARO: "FIRST Championship - Houston - GARO Division",
  GGGT: "Gitchi Gummi Get-Together",
  GRITS: "Georgia Robotics Invitational Tournament & Showcase",
  GUSH: "Shenzhen Regional",
  HIHO: "Hawaii Regional",
  HOPPER: "FIRST Championship - Houston - Hopper Subdivision",
  HOTU: "FIRST Championship - Houston - HOTU Division",
  IACF: "Iowa Regional",
  IDBO: "Idaho Regional",
  ILCH: "Midwest Regional",
  ILPE: "Central Illinois Regional",
  INCMP: "Indiana State Championship",
  INMIS: "IN District - St. Joseph Event",
  INPMH: "IN District - Perry Meridian Event",
  INWLA: "IN District - Tippecanoe Event",
  IRI: "Indiana Robotics Invitational",
  IROC: "IROC",
  ISCMP: "FIRST Israel District Championship",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  LAKE: "Bayou Regional",
  MABOS: "NE District - Greater Boston Event",
  MABRI: "NE District - SE Mass Event",
  MAREA: "NE District - North Shore Event",
  MAWOR: "NE District - Worcester Polytechnic Institute Event",
  MDBET: "CHS District - Greater DC Event sponsored by Accenture",
  MDBOB: "Battle O’ Baltimore",
  MDEDG: "CHS District - Central Maryland Event sponsored by Leidos",
  MDOWI: "CHS District - Northern Maryland Event",
  MELEW: "NE District - Pine Tree Event",
  MEMS: "Mainely SPIRIT 7",
  MESH: "Summer Heat",
  MIANN: "FIM District - Ann Arbor Pioneer Event",
  MIBRO: "FIM District - Woodhaven Event",
  MICEN: "FIM District - Center Line Event",
  MICMP: "Michigan State Championship",
  MICMP1: "Michigan State Championship - Consumers Energy Division",
  MICMP2: "Michigan State Championship - Dow Division",
  MICMP3: "Michigan State Championship - DTE Energy Foundation Division",
  MICMP4: "Michigan State Championship - Ford Division",
  MIESC: "FIM District - Escanaba Event",
  MIGAY: "FIM District - Gaylord Event",
  MIGUL: "FIM District - Gull Lake Event",
  MIHOW: "FIM District - Howell Event",
  MIKE2: "FIM District - Kettering University Event #2",
  MIKEN: "FIM District - East Kentwood Event",
  MIKET: "FIM District - Kettering University Event #1",
  MILAK: "FIM District - Lakeview Event",
  MILAN: "FIM District - Lansing Event",
  MILIV: "FIM District - Livonia Event",
  MILSU: "FIM District - Lake Superior State University Event",
  MIMAR: "FIM District - Marysville Event",
  MIMID: "FIM District - Midland Event",
  MISHE: "FIM District - Shepherd Event",
  MISJO: "FIM District - St. Joseph Event",
  MISOU: "FIM District - Southfield Event",
  MITRY: "FIM District - Troy Event",
  MITVC: "FIM District - Traverse City Event",
  MIWAT: "FIM District - Waterford Event",
  MIWMI: "FIM District - West Michigan Event",
  MNCL: "Northern Minnesota Robotics Conference Tournament",
  MNCMP: "MSHSL FIRST State Robotics Championship",
  MNDU: "Lake Superior Regional",
  MNDU2: "Northern Lights Regional",
  MNMI: "Minnesota 10000 Lakes Regional",
  MNMI2: "Minnesota North Star Regional",
  MNRI: "Minnesota Robotics Invitational",
  MOKC: "Greater Kansas City Regional",
  MOSL: "St. Louis Regional",
  MRCMP:
    "FIRST Mid-Atlantic District Championship sponsored by Johnson & Johnson",
  MXTL: "Toluca Regional",
  MXTO: "Laguna Regional",
  NCASH: "NC District - UNC Asheville Event",
  NCCMP: "FIRST North Carolina State Championship",
  NCGRE: "NC District - Greensboro Event",
  NCRAL: "NC District - Raleigh Event",
  NCWIN: "NC District - Pitt County Event",
  NECMP: "New England District Championship",
  NEWTON: "FIRST Championship - Houston - Newton Subdivision",
  NHBED: "NE District - Southern NH Event",
  NHBOB: "Battle Of the Bay",
  NHGRS: "NE District - Granite State Event",
  NHRR: "RiverRage 21",
  NJBE: "Brunswick Eruption",
  NJBRI: "MAR District - Bridgewater-Raritan Event",
  NJFLA: "MAR District - Mount Olive Event",
  NJSKI: "MAR District - Montgomery Event",
  NJTAB: "MAR District - Seneca Event",
  NTTR: "North Texas Tournament of Robots",
  NVLV: "Las Vegas Regional",
  NYLI: "SBPLI Long Island Regional",
  NYNY: "New York City Regional",
  NYRO: "Finger Lakes Regional ",
  NYSU: "Hudson Valley Regional",
  NYTR: "New York Tech Valley Regional",
  OHCL: "Buckeye Regional",
  OHSP: "Miami Valley Regional",
  OKOK: "Oklahoma Regional ",
  ONBAR: "ONT District - Georgian College Event",
  ONCMP: "FIRST Ontario Provincial Championship",
  ONHA2: "STEMley Cup",
  ONHAM: "ONT District - McMaster University Event",
  ONLON: "ONT District - Western University, Engineering Event",
  ONNOB: "ONT District - North Bay Event",
  ONOSH: "ONT District - Durham College Event",
  ONTO1: "ONT District - Ryerson University Event",
  ONTO2: "ONT District - Victoria Park Collegiate Event",
  ONWAT: "ONT District - University of Waterloo Event",
  ONWIN: "ONT District - Windsor Essex Great Lakes Event",
  ORLAK: "PNW District - Lake Oswego Event",
  ORORE: "PNW District - Clackamas Academy of Industrial Science Event",
  ORWIL: "PNW District - Wilsonville Event",
  PACA: "Greater Pittsburgh Regional",
  PAHAT: "MAR District - Hatboro-Horsham Event",
  PAPHI: "MAR District - Springside Chestnut Hill Academy Event",
  PAWCH: "MAR District - Westtown Event",
  PNCMP: "Pacific Northwest District Championship",
  QCMO: "Festival de Robotique - Montreal Regional",
  R2OC: "Rock River Off-Season Competition",
  RIPRO: "NE District - Rhode Island Event",
  ROEBLING: "FIRST Championship - Houston - Roebling Subdivision",
  SCMB: "Palmetto Regional",
  TESLA: "FIRST Championship - St. Louis - Tesla Subdivision",
  TNKN: "Smoky Mountains Regional",
  TURING: "FIRST Championship - Houston - Turing Subdivision",
  TXDA: "Dallas Regional",
  TXHO: "Lone Star Central Regional",
  TXLU: "Hub City Regional",
  TXRI: "Texas Robotics Invitational",
  TXRR: "Texas Robot Roundup",
  TXSA: "Alamo Regional",
  TXTR: "The Remix",
  TXWA: "Brazos Valley Regional",
  TXWO: "Lone Star North Regional",
  UTWV: "Utah Regional",
  VABLA: "CHS District - Southwest Virginia Event",
  VAGLE: "CHS District - Central Virginia Event",
  VAHAY: "CHS District - Northern Virginia Event sponsored by Bechtel",
  VAPOR:
    "CHS District - Hampton Roads Event sponsored by Newport News Shipbuilding",
  WAAHS: "PNW District - Auburn Event",
  WAAMV: "PNW District - Auburn Mountainview Event",
  WAELL: "PNW District - Central Washington University Event",
  WAGG: "Washington Girls Generation",
  WAMOU: "PNW District - Mount Vernon Event",
  WAPP: "Peak Performance",
  WASNO: "PNW District - Glacier Peak Event",
  WASPO: "PNW District - West Valley Event",
  WEEK0: "Week 0",
  WILA: "Seven Rivers Regional",
  WIMI: "Wisconsin Regional",
};

const events2018 = {
  ABCA: "Canadian Rockies Regional",
  ALHU: "Rocket City Regional",
  ARCHIMEDES: "FIRST Championship - Detroit - Archimedes Subdivision",
  ARDA: "FIRST Championship - Detroit - ARDA Division",
  ARLI: "Arkansas Rock City Regional",
  AUSC: "Southern Cross Regional",
  AUSP: "South Pacific Regional",
  AZFL: "Arizona North Regional",
  AZPX: "Arizona West Regional",
  AZTEM:
    "Sanghi Foundation FIRST Robotics Competition Arizona State Championship",
  BC19: "BattleCry 19",
  BCVI: "Canadian Pacific Regional",
  CAAV: "Aerospace Valley Regional",
  CADA: "Sacramento Regional",
  CAFR: "Central Valley Regional",
  CAIR: "Orange County Regional",
  CANE: "FIRST Championship - Houston - CANE Division",
  CAPO: "Los Angeles Regional",
  CARSON: "FIRST Championship - Detroit - Carson Subdivision",
  CARVER: "FIRST Championship - Houston - Carver Subdivision",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  CASJ: "Silicon Valley Regional",
  CATE: "FIRST Championship - Detroit - CATE Division",
  CAVE: "Ventura Regional",
  CHCMP: "FIRST Chesapeake District Championship",
  CMPMI: "FIRST Championship - Detroit",
  CMPTX: "FIRST Championship - Houston",
  CODE: "Colorado Regional",
  CTHAR: "NE District Hartford Event",
  CTSCT: "NE District Southern CT Event",
  CTWAT: "NE District Waterbury Event",
  CUDA: "FIRST Championship - Detroit - CUDA Division",
  CURIE: "FIRST Championship - Detroit - Curie Subdivision",
  DALY: "FIRST Championship - Detroit - Daly Subdivision",
  DARWIN: "FIRST Championship - Detroit - Darwin Subdivision",
  FLOR: "Orlando Regional",
  FLWP: "South Florida Regional ",
  GAALB: "PCH District Albany Event",
  GACMP: "Peachtree District State Championship",
  GACOL: "PCH District Columbus Event",
  GADAL: "PCH District Dalton Event",
  GADUL: "PCH District Duluth Event",
  GAGAI: "PCH District Gainesville Event",
  GALILEO: "FIRST Championship - Houston - Galileo Subdivision",
  GARO: "FIRST Championship - Houston - GARO Division",
  GAROS: "GRITS 2018",
  GUSH: "Shenzhen Regional",
  HIHO: "Hawaii Regional",
  HOPPER: "FIRST Championship - Houston - Hopper Subdivision",
  HOTU: "FIRST Championship - Houston - HOTU Division",
  IACF: "Iowa Regional",
  IDBO: "Idaho Regional",
  ILCH: "Midwest Regional",
  ILPE: "Central Illinois Regional",
  ILROC: "Rock River Offseason Competition",
  INCMP: "Indiana State Championship",
  INMIS: "IN District St. Joseph Event",
  INPLA: "IN District Plainfield Event sponsored by Toyota",
  INWES: "Boiler Bot Battle",
  INWLA: "IN District Tippecanoe Event",
  IRI: "Indiana Robotics Invitational",
  ISCMP: "FIRST Israel District Championship",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  LAKE: "Bayou Regional",
  MABOS: "NE District Greater Boston Event",
  MABRI: "NE District SE Mass Event",
  MAREA: "NE District North Shore Event",
  MAWOR: "NE District Worcester Polytechnic Institute Event",
  MDEDG: "CHS District Central Maryland Event sponsored by Leidos",
  MDOWI: "Battle O’ Baltimore",
  MDOXO: "CHS District Southern Maryland Event",
  MELEW: "NE District Pine Tree Event",
  MESOU: "Summer Heat",
  MIALP: "FIM District Alpena Event",
  MIBEL: "FIM District Belleville Event",
  MIBIG: "FSU Roboday",
  MICEN: "FIM District Center Line Event",
  MICMP: "Michigan State Championship",
  MICMP1: "Michigan State Championship - DTE Energy Foundation Division",
  MICMP2: "Michigan State Championship - Consumers Energy Division",
  MICMP3: "Michigan State Championship - Ford Division",
  MICMP4: "Michigan State Championship - Dow Division",
  MIESC: "FIM District Escanaba Event",
  MIFOR: "FIM District Forest Hills Event",
  MIGAY: "FIM District Gaylord Event",
  MIGIB: "FIM District Gibraltar Event",
  MIGUL: "FIM District Gull Lake Event",
  MIKE2: "FIM District Kettering University Event #2",
  MIKEN: "FIM District East Kentwood Event",
  MIKET: "FIM District Kettering University Event #1",
  MILAK: "FIM District Lakeview Event",
  MILAN: "FIM District Lansing Event",
  MILIN: "FIM District Lincoln Event",
  MILIV: "FIM District Livonia Event",
  MILSU: "FIM District Lake Superior State University Event",
  MIMAR: "FIM District Marysville Event",
  MIMID: "FIM District Midland Event",
  MIMID2: "Great Lakes Bay Bot Bash",
  MIMIL: "FIM District Milford Event",
  MISHE: "FIM District Shepherd Event",
  MISJO: "FIM District St. Joseph Event",
  MISOU: "FIM District Southfield Event",
  MITRY: "FIM District Troy Event",
  MITVC: "FIM District Traverse City Event",
  MIWAT: "FIM District Waterford Event",
  MIWMI: "FIM District West Michigan Event",
  MNBEM: "NMRC Championship",
  MNCMP: "MSHSL FIRST State Robotics Championship",
  MNDU: "Lake Superior Regional",
  MNDU2: "Northern Lights Regional",
  MNMI: "Medtronic Foundation Regional",
  MNMI2: "Minnesota North Star Regional",
  MNROS: "Minnesota Robotics Invitational",
  MNWOO: "EMCC",
  MOKC: "Greater Kansas City Regional",
  MOKC2: "Heartland Regional",
  MOLEE: "Cow Town ThrowDown",
  MOSL: "St. Louis Regional",
  MOSTC: "Gateway Robotics Challenge",
  MRCMP: "FIRST Mid-Atlantic District Championship",
  MXMO: "Monterrey Regional",
  MXTO: "Laguna Regional",
  NCASH: "NC District UNC Asheville Event",
  NCCHA: "Thundering Herd of Robots (THOR)",
  NCCMP: "FIRST North Carolina State Championship",
  NCGRE: "NC District Pitt County Event",
  NCPEM: "NC District UNC Pembroke Event",
  NCWIN: "NC District Forsyth County Event",
  NDGF: "Great Northern Regional",
  NECMP: "New England District Championship",
  NEWTON: "FIRST Championship - Houston - Newton Subdivision",
  NHALT: "Battle Of the Bay 5",
  NHDUR: "NE District UNH Event",
  NHGRS: "NE District Granite State Event",
  NHHOL: "Governor's Cup",
  NHMAN: "RiverRage 22",
  NJBRI: "MAR District Bridgewater-Raritan Event",
  NJFLA: "MAR District Mount Olive Event",
  NJNOR: "Brunswick Eruption 2018",
  NJSKI: "MAR District Montgomery Event",
  NJTAB: "MAR District Seneca Event",
  NVLV: "Las Vegas Regional",
  NYDIX: "Half Hollow Hills Invitational",
  NYLI: "SBPLI Long Island Regional #1",
  NYLI2: "SBPLI Long Island Regional #2",
  NYNY: "New York City Regional",
  NYRO: "Finger Lakes Regional ",
  NYSU: "Hudson Valley Regional",
  NYTR: "New York Tech Valley Regional",
  NYUT: "Central New York Regional",
  OHAUS: "Mahoning Valley Robotics Challenge",
  OHCL: "Buckeye Regional",
  OHCOL: "2018 CORI Invitational",
  OHMV: "Miami Valley Regional",
  OHWE: "WOW Alliance District Championship",
  OKOK: "Oklahoma Regional ",
  ONBAR: "ONT District Georgian College Event",
  ONCMP: "FIRST Ontario Provincial Championship",
  ONCMP1: "FIRST Ontario Provincial Championship - Science Division",
  ONCMP2: "FIRST Ontario Provincial Championship - Technology Division",
  ONHAM: "ONT District McMaster University Event",
  ONLON: "ONT District Western University, Western Engineering Event",
  ONNOB: "ONT District North Bay Event",
  ONNYO: "ONT District York University Event",
  ONOSH: "ONT District Durham College Event",
  ONTO1: "ONT District Ryerson University Event",
  ONTOR: "Robots@CNE",
  ONWAT: "ONT District University of Waterloo Event",
  ONWIN: "ONT District Windsor Essex Great Lakes Event",
  ORLAK: "PNW District Lake Oswego Event",
  ORORE: "PNW District Clackamas Academy Event",
  ORWIL: "PNW District Wilsonville Event",
  PACA: "Greater Pittsburgh Regional",
  PAHAT: "MAR District Hatboro-Horsham Event",
  PAPHI: "MAR District Springside Chestnut Hill Academy Event",
  PAWCH: "MAR District Westtown Event",
  PNCMP: "Pacific Northwest District Championship",
  QCMO: "Festival de Robotique - Montreal Regional",
  RISMI: "NE District Rhode Island Event",
  ROEBLING: "FIRST Championship - Houston - Roebling Subdivision",
  SCCOL: "South Carolina Robotics Invitational & Workshops VIII",
  SCMB: "Palmetto Regional",
  SHMI: "Shanghai Regional",
  TESLA: "FIRST Championship - Detroit - Tesla Subdivision",
  TNKN: "Smoky Mountains Regional",
  TUIS: "Istanbul Regional",
  TURING: "FIRST Championship - Houston - Turing Subdivision",
  TXCMP: "Texas UIL State Championship",
  TXDA: "Dallas Regional",
  TXEL: "El Paso Regional",
  TXHO: "Lone Star Central Regional",
  TXLU: "Hub City Regional",
  TXPA: "Lone Star South Regional",
  TXRI: "Texas Robotics Invitational",
  TXSA: "Alamo Regional",
  UTWV: "Utah Regional",
  VABLA: "CHS District Southwest Virginia Event sponsored by Anton Paar",
  VAGDC: "CHS District Greater DC Event co-sponsored by Micron",
  VAGLE: "CHS District Central Virginia Event sponsored by Dominion Energy",
  VAHAY: "CHS District Northern Virginia Event",
  VAHAY2: "IROC",
  VAPOR:
    "CHS District Hampton Roads Event sponsored by Newport News Shipbuilding",
  WAAHS: "PNW District Auburn Event",
  WAAMV: "PNW District Auburn Mountainview Event",
  WAMAP: "Washington Girls' Generation 2018",
  WAMOU: "PNW District Mount Vernon Event",
  WAPP: "Peak Performance",
  WASNO: "PNW District Glacier Peak Event",
  WASPO: "PNW District West Valley Event",
  WAYAK: "PNW District SunDome Event",
  WEEK0: "Week 0",
  WILA: "Seven Rivers Regional",
  WIMI: "Wisconsin Regional",
};

const events2019 = {
  ABCA: "Canadian Rockies Regional",
  ALHU: "Rocket City Regional",
  ARCHIMEDES: "FIRST Championship - Detroit - Archimedes Subdivision",
  ARDA: "FIRST Championship - Detroit - ARDA Division",
  ARLI: "Arkansas Rock City Regional",
  AUSC: "Southern Cross Regional",
  AUSP: "South Pacific Regional",
  AZFL: "Arizona North Regional",
  AZPX: "Arizona West Regional",
  AZTEM: "Sanghi Foundation Arizona FIRST® State Championship",
  BC20: "BattleCry 20",
  BCVI: "Canadian Pacific Regional",
  BRNOV: "Off-Season Brazil",
  CAAV: "Aerospace Valley Regional",
  CADA: "Sacramento Regional",
  CADM: "Del Mar Regional presented by Qualcomm",
  CAELK: "Capital City Classic",
  CAFR: "Central Valley Regional",
  CAHUN: "Beach Blitz",
  CALA: "Los Angeles Regional",
  CALN: "Los Angeles North Regional",
  CAMB: "Monterey Bay Regional",
  CANE: "FIRST Championship - Houston - CANE Division",
  CAOC: "Orange County Regional",
  CAPLA: "So Cal Fall Classic",
  CARSON: "FIRST Championship - Detroit - Carson Subdivision",
  CARVER: "FIRST Championship - Houston - Carver Subdivision",
  CASA2: "Battle at the Border",
  CASAN: "Battleship Blast at LA Fleet Week",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  CASJ: "Silicon Valley Regional",
  CATE: "FIRST Championship - Detroit - CATE Division",
  CAVE: "Ventura Regional",
  CHCMP: "FIRST Chesapeake District Championship",
  CMPMI: "FIRST Championship - Detroit - FIRST Robotics Competition",
  CMPTX: "FIRST Championship - Houston - FIRST Robotics Competition",
  CODE: "Colorado Regional",
  CTHAR: "NE District Hartford Event",
  CTWAT: "NE District Waterbury Event",
  CUDA: "FIRST Championship - Detroit - CUDA Division",
  CURIE: "FIRST Championship - Detroit - Curie Subdivision",
  DALY: "FIRST Championship - Detroit - Daly Subdivision",
  DARWIN: "FIRST Championship - Detroit - Darwin Subdivision",
  FLMEL: "Space Coast Showdown",
  FLOR: "Orlando Regional",
  FLTAM: "ROBOTICON Tampa Bay",
  FLWP: "South Florida Regional",
  FTCMP: "FIRST In Texas District Championship",
  GAALB: "PCH District Albany Event presented by Procter & Gamble",
  GACMP: "Peachtree District State Championship",
  GACOL: "PCH District Columbus Event",
  GADAL: "PCH District Dalton Event",
  GAFOR: "PCH District Forsyth District Event",
  GAGAI: "PCH District Gainesville Event",
  GALILEO: "FIRST Championship - Houston - Galileo Subdivision",
  GAMAR: "GRITS 2019 – Deep Space",
  GARO: "FIRST Championship - Houston - GARO Division",
  HIHO: "Hawaii Regional",
  HOPPER: "FIRST Championship - Houston - Hopper Subdivision",
  HOTU: "FIRST Championship - Houston - HOTU Division",
  IACF: "Iowa Regional",
  IDBO: "Idaho Regional",
  ILCH: "Midwest Regional",
  ILPE: "Central Illinois Regional",
  ILROC: "Rock River Robotics Off-Season Competition",
  INCMP: "Indiana State Championship",
  INGRE: "IN District Center Grove Event sponsored by Toyota",
  INMIS: "IN District St. Joseph Event",
  INWES: "Boiler Bot Battle",
  INWLA: "IN District Tippecanoe Event",
  IRI: "Indiana Robotics Invitational",
  ISCMP: "FIRST Israel District Championship",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  KSLA: "Heartland Regional",
  KSSAL: "Great Plains Robot Roundup",
  LAKE: "Bayou Regional",
  MABOS: "NE District Greater Boston Event",
  MABRI: "NE District SE Mass Event",
  MACMA: "NE District Central Mass Event",
  MAREA: "NE District North Shore Event",
  MAREV: "Beantown Blitz",
  MAWNE: "NE District Western NE Event",
  MDBET: "CHS District Bethesda MD Event sponsored by Bechtel",
  MDOW2: "Battle O’ Baltimore",
  MDOWI: "CHS District Owings Mills MD Event sponsored by Leidos",
  MDOXO: "CHS District Oxon Hill MD Event",
  MELEW: "NE District Pine Tree Event",
  MESOU: "Summer Heat",
  MIAL2: "FIM District Alpena Event #2",
  MIALP: "FIM District Alpena Event #1",
  MIBEL: "FIM District Belleville Event",
  MIBI2: "FSU Roboday",
  MIBLO: "Bloomfield Girls Robotics Competition 2019",
  MIBRO: "Goonettes Invitational",
  MICEN: "FIM District Center Line Event",
  MICMP: "Michigan State Championship",
  MICMP1: "Michigan State Championship - DTE Energy Foundation Division",
  MICMP2: "Michigan State Championship - Consumers Energy Division",
  MICMP3: "Michigan State Championship - Ford Division",
  MICMP4: "Michigan State Championship - Dow Division",
  MIDET: "FIM District Detroit Event",
  MIDUN: "MARC II (Michigan Advanced Robotics Competition II)",
  MIFLI: "Kettering Kickoff",
  MIFOR: "FIM District Forest Hills Event",
  MIGIB: "FIM District Gibraltar Event",
  MIGUL: "FIM District Gull Lake Event",
  MIJAC: "FIM District Jackson Event",
  MIKE2: "FIM District Kettering University Event #2",
  MIKEN: "FIM District East Kentwood Event",
  MIKET: "FIM District Kettering University Event #1",
  MIKNG: "FIM District Kingsford Event",
  MILA2: "Mid-Mitten Roborodeo ",
  MILAK: "FIM District Lakeview Event",
  MILAN: "FIM District Lansing Event",
  MILAP: "ROBO-CON FRC Off Season Competition 2019",
  MILIN: "FIM District Lincoln Event",
  MILIV: "FIM District Livonia Event",
  MILSU: "FIM District Lake Superior State University Event",
  MIMAR: "FIM District Marysville Event",
  MIMI2: "Great Lakes Bay Bot Bash",
  MIMID: "FIM District Midland Event",
  MIMIL: "FIM District Milford Event",
  MIMUS: "FIM District Muskegon Event",
  MISHE: "FIM District Shepherd Event",
  MISJO: "FIM District St. Joseph Event",
  MISOU: "FIM District Southfield Event",
  MITRY: "FIM District Troy Event",
  MITVC: "FIM District Traverse City Event",
  MIWMI: "FIM District West Michigan Event",
  MIWYO: "Grand Rapids Girls Robotics competition ",
  MNCMP: "MSHSL FIRST State Robotics Championship",
  MNDU: "Lake Superior Regional",
  MNDU2: "Northern Lights Regional",
  MNMI: "Minnesota 10,000 Lakes Regional presented by the Medtronic Foundation",
  MNMI2: "Minnesota North Star Regional",
  MNROS: "Minnesota Robotics Invitational",
  MNTHI: "Northern Minnesota Robotics Conference (NMRC) Championship",
  MNWOO: "EMCC 2019",
  MOCMP: "Missouri State FRC Championship",
  MOKC: "Greater Kansas City Regional",
  MOKC3: "Central Missouri Regional",
  MOLEE: "Cow Town ThrowDown",
  MOSL: "St. Louis Regional",
  MOSTC: "Gateway Robotics Challenge (GRC)",
  MRCMP: "FIRST Mid-Atlantic District Championship",
  MXCM: "Regional de la Ciudad de Mexico",
  MXMO: "Regional Monterrey",
  MXTO: "Regional Laguna",
  NCASH: "FNC District UNC Asheville Event",
  NCCMP: "FIRST North Carolina State Championship",
  NCGUI: "FNC District Guilford County Event",
  NCHAM: "THOR East",
  NCHEN: "THOR West",
  NCPEM: "FNC District UNC Pembroke Event",
  NCWAK: "FNC District Wake County Event",
  NDGF: "Great Northern Regional",
  NECMP: "New England District Championship",
  NEWTON: "FIRST Championship - Houston - Newton Subdivision",
  NHALT: "Battle of the Bay",
  NHDUR: "NE District UNH Event",
  NHGRS: "NE District Granite State Event",
  NHMAN: "RiverRage 23 2019",
  NHSNH: "NE District Southern NH Event",
  NJBRI: "FMA District Bridgewater-Raritan Event",
  NJFLA: "FMA District Mount Olive Event",
  NJNOR: "Brunswick Eruption ",
  NJSKI: "FMA District Montgomery Event",
  NJTAB: "FMA District Seneca Event",
  NVLV: "Las Vegas Regional",
  NYBAL: "Tech Valley Robot Rumble",
  NYDIX: "HHH Robotics Invitational",
  NYLI: "SBPLI Long Island Regional #1",
  NYLI2: "SBPL2 Long Island Regional #2",
  NYNY: "New York City Regional",
  NYRO: "Finger Lakes Regional",
  NYROC: "RahChaCha Ruckus",
  NYSU: "Hudson Valley Regional",
  NYTR: "New York Tech Valley Regional",
  NYUT: "Central New York Regional",
  OHCL: "Buckeye Regional",
  OHCLE: "Curiosity Open:  Great Lakes Science Center Robotics Challenge",
  OHCO2: "CORI Invitational ",
  OHMV: "Miami Valley Regional",
  OHWA2: "Mahoning Valley Robotics Challenge",
  OHWE: "WOW Alliance Championship",
  OKOK: "Oklahoma Regional",
  ONBAR: "ONT District Georgian College Event",
  ONCMP: "FIRST Ontario Provincial Championship",
  ONCMP1: "FIRST Ontario Provincial Championship - Science Division",
  ONCMP2: "FIRST Ontario Provincial Championship - Technology Division",
  ONHA2: "STEMley Cup Championship",
  ONHA3: "Overtime Sunday",
  ONHAM: "ONT District McMaster University Event",
  ONLON: "ONT District Western University, Western Engineering Event",
  ONNOB: "ONT District North Bay Event",
  ONNYO: "ONT District York University Event",
  ONOSH: "ONT District Durham College Event",
  ONTO1: "ONT District Ryerson University Event",
  ONTO3: "ONT District Humber College Event",
  ONTOR: "Robots@CNE 2019",
  ONWAT: "ONT District University of Waterloo Event",
  ONWIN: "ONT District Windsor Essex Great Lakes Event",
  ORLAK: "PNW District Lake Oswego Event",
  ORORE: "PNW District Clackamas Academy Event",
  ORWI2: "Oregon Girls' Generation",
  ORWIL: "PNW District Wilsonville Event",
  PAAMB: "Ramp Riot",
  PABEN: "FMA District Bensalem Event",
  PACA: "Greater Pittsburgh Regional",
  PAHAT: "FMA District Hatboro-Horsham Event",
  PAPHI: "FMA District Springside Chestnut Hill Academy Event",
  PAPIT: "Steel City Showdown",
  PAWCH: "FMA District Westtown Event",
  PNCMP: "Pacific Northwest FIRST District Championship",
  QCMO: "Festival de Robotique a Montreal Regional",
  QCQC: "Festival de Robotique a Quebec City Regional",
  RISMI: "NE District Rhode Island Event",
  ROEBLING: "FIRST Championship - Houston - Roebling Subdivision",
  SCCO2: "South Carolina Robotics Invitational and Workshops",
  SCMB: "Palmetto Regional",
  TESLA: "FIRST Championship - Detroit - Tesla Subdivision",
  TNKN: "Smoky Mountains Regional",
  TUANK: "Ankara Off-Season 2019",
  TUIS: "Istanbul Regional",
  TUIS2: "Bosphorus Regional",
  TUMER: "Mersin'19 Off-Season | TR",
  TURING: "FIRST Championship - Houston - Turing Subdivision",
  TXAMA: "FIT District Amarillo Event",
  TXAUS: "FIT District Austin Event",
  TXCHA: "FIT District Channelview Event",
  TXDEL: "FIT District Del Rio Event",
  TXDLS: "FIT District Dallas Event",
  TXELP: "FIT District El Paso Event",
  TXGRE: "FIT District Greenville Event",
  TXHOU: "Texas Robotics Invitational",
  TXPAS: "FIT District Pasadena Event",
  TXPLA: "FIT District Plano Event",
  TXROC: "NTX Tournament of Robots and STEM Gals Off-Season Competition",
  TXSAN: "FIT District San Antonio Event",
  TXTHE: "The Remix",
  UTWV: "Utah Regional",
  VABLA: "CHS District Blacksburg VA Event",
  VAGLE: "CHS District Richmond VA Event sponsored by Dominion Energy",
  VAHA2: "IROC (ILITE Robotics Offseason Challenge)",
  VAHAY: "CHS District Haymarket VA Event sponsored by Booz Allen Hamilton",
  VANEW: "Rumble in the Roads",
  VAPOR:
    "CHS District Portsmouth VA Event sponsored by Newport News Shipbuilding",
  VASAL: "Blue Ridge Brawl",
  WAAHS: "PNW District Auburn Event",
  WAAMV: "PNW District Auburn Mountainview Event",
  WAMAP: "Washington Girls' Generation",
  WAMOU: "PNW District Mount Vernon Event",
  WASEA: "Peak Performance",
  WASNO: "PNW District Glacier Peak Event",
  WASPO: "PNW District West Valley Event",
  WAYAK: "PNW District SunDome Event",
  WEEK0: "Week 0",
  WILA: "Seven Rivers Regional",
  WIMI: "Wisconsin Regional",
};

const events2020 = {
  ABCA: "* Canadian Rockies Regional (Cancelled)",
  ALHU: "* Rocket City Regional (Cancelled)",
  ARCHIMEDES: "FIRST Championship - Detroit - Archimedes Division (Cancelled)",
  ARLI: "Arkansas Regional",
  AUSC: "* Southern Cross Regional (Cancelled)",
  AUSP: "* South Pacific Regional (Cancelled)",
  AZFL: "* Arizona North Regional (Cancelled)",
  AZPX: "* Arizona West Regional (Cancelled)",
  BCVI: "Canadian Pacific Regional",
  BEXI: "* Beijing Cultural Exchange Event #1 (Cancelled)",
  BEXI2: "* Beijing Cultural Exchange Event #2 (Cancelled)",
  CAAV: "* Aerospace Valley Regional (Cancelled)",
  CADA: "* Sacramento Regional (Cancelled)",
  CADM: "Del Mar Regional",
  CAFR: "* Central Valley Regional (Cancelled)",
  CALA: "Los Angeles Regional",
  CALN: "Los Angeles North Regional",
  CAMB: "* Monterey Bay Regional (Cancelled)",
  CAOC: "* Orange County Regional (Cancelled)",
  CARSON: "FIRST Championship - Detroit - Carson Division (Cancelled)",
  CARVER: "FIRST Championship - Houston - Carver Division (Cancelled)",
  CASD: "* San Diego Regional presented by Qualcomm (Cancelled)",
  CASF: "* San Francisco Regional (Cancelled)",
  CASJ: "* Silicon Valley Regional (Cancelled)",
  CAVE: "* Ventura Regional (Cancelled)",
  CHCMP:
    "* FIRST Chesapeake District Championship sponsored by Newport News Shipbuilding (Cancelled)",
  CHTA: "* Science Park Taichung Regional (Cancelled)",
  CMPMI:
    "FIRST Championship - Detroit - FIRST Robotics Competition (Cancelled)",
  CMPTX:
    "FIRST Championship - Houston - FIRST Robotics Competition (Cancelled)",
  CODE: "* Colorado Regional (Cancelled)",
  CTHAR: "* NE District Hartford Event (Cancelled)",
  CTNCT: "NE District Northern CT Event",
  CTWAT: "NE District Waterbury Event",
  CURIE: "FIRST Championship - Detroit - Curie Division (Cancelled)",
  DALY: "FIRST Championship - Detroit - Daly Division (Cancelled)",
  DARWIN: "FIRST Championship - Detroit - Darwin Division (Cancelled)",
  FLOR: "* Orlando Regional (Cancelled)",
  FLWP: "* South Florida Regional (Cancelled)",
  FTCMP: "* FIRST In Texas District Championship (Cancelled)",
  GAALB:
    "* PCH District Albany Event presented by Procter & Gamble (Cancelled)",
  GACAR: "* PCH District Carrollton Event (Cancelled)",
  GACMP: "* Peachtree District State Championship (Cancelled)",
  GACOL: "* PCH District Columbus Event (Cancelled)",
  GADAL: "PCH District Dalton Event",
  GAGAI: "PCH District Gainesville Event presented by Automation Direct",
  GALILEO: "FIRST Championship - Houston - Galileo Division (Cancelled)",
  HIHO: "* Hawaii Regional (Cancelled)",
  HOPPER: "FIRST Championship - Houston - Hopper Division (Cancelled)",
  IACF: "* Iowa Regional (Cancelled)",
  IDBO: "* Idaho Regional (Cancelled)",
  ILCH: "Midwest Regional",
  ILPE: "* Central Illinois Regional (Cancelled)",
  INBLO: "IN District Bloomington Event",
  INCMP: "* Indiana State Championship (Cancelled)",
  INCOL: "* IN District Columbus Event (Cancelled)",
  INMIS: "* IN District St. Joseph Event (Cancelled)",
  INPMH: "* IN District Perry Meridian Event (Cancelled)",
  ISCMP: "* FIRST Israel District Championship (Cancelled)",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "* ISR District Event #3 (Cancelled)",
  ISDE4: "* ISR District Event #4 (Cancelled)",
  KSLA: "* Heartland Regional (Cancelled)",
  LAKE: "* Bayou Regional (Cancelled)",
  MABOS: "* NE District Greater Boston Event (Cancelled)",
  MABRI: "NE District SE Mass Event",
  MAREA: "* NE District North Shore Event (Cancelled)",
  MAWNE: "* NE District Western NE Event (Cancelled)",
  MAWOR: "* NE District WPI Event (Cancelled)",
  MDBET: "CHS District Bethesda MD Event sponsored by Accenture",
  MDEDG: "* CHS District Edgewater MD Event sponsored by Leidos (Cancelled)",
  MDOWI: "* CHS District Owings Mills MD Event (Cancelled)",
  MELEW: "* NE District Pine Tree Event (Cancelled)",
  MIAL2: "* FIM District Alpena Event #2 (Cancelled)",
  MIALP: "* FIM District Alpena Event #1 (Cancelled)",
  MIBEL: "* FIM District Belleville Event (Cancelled)",
  MICEN: "* FIM District Center Line Event (Cancelled)",
  MICMP: "* Michigan State Championship (Cancelled)",
  MIDET: "* FIM District Detroit Event (Cancelled)",
  MIESC: "* FIM District Escanaba Event (Cancelled)",
  MIFER: "* FIM District Ferris State Event (Cancelled)",
  MIGUL: "* FIM District Gull Lake Event (Cancelled)",
  MIJAC: "FIM District Jackson Event",
  MIKE2: "FIM District Kettering University Event #2",
  MIKEN: "* FIM District East Kentwood Event (Cancelled)",
  MIKET: "FIM District Kettering University Event #1",
  MIKNG: "FIM District Kingsford Event",
  MILAK: "* FIM District Lakeview Event (Cancelled)",
  MILAN: "* FIM District Lansing Event (Cancelled)",
  MILIN: "* FIM District Lincoln Event (Cancelled)",
  MILIV: "* FIM District Livonia Event (Cancelled)",
  MIMAR: "* FIM District Marysville Event (Cancelled)",
  MIMCC: "FIM District Macomb Community College Event",
  MIMID: "* FIM District Midland Event (Cancelled)",
  MIMIL: "FIM District Milford Event",
  MIMUS: "* FIM District Muskegon Event (Cancelled)",
  MISHE: "* FIM District Shepherd Event (Cancelled)",
  MISJO: "FIM District St. Joseph Event",
  MISOU: "FIM District Southfield Event",
  MITRY: "* FIM District Troy Event (Cancelled)",
  MITVC: "FIM District Traverse City Event",
  MIWMI: "* FIM District West Michigan Event (Cancelled)",
  MIWOO: "* FIM District Woodhaven Event (Cancelled)",
  MNDU: "Lake Superior Regional",
  MNDU2: "Northern Lights Regional",
  MNMI: "* Minnesota 10,000 Lakes Regional Presented by the Medtronic Foundation (Cancelled)",
  MNMI2: "* Minnesota North Star Regional (Cancelled)",
  MOKC: "Greater Kansas City Regional",
  MOKC3: "* Central Missouri Regional (Cancelled)",
  MOSL: "* St. Louis Regional (Cancelled)",
  MRCMP: "* FIRST Mid-Atlantic District Championship (Cancelled)",
  MXMO: "Regional Monterrey",
  MXTO: "* Regional Laguna (Cancelled)",
  NCASH: "* FNC District UNC Asheville Event (Cancelled)",
  NCCMP: "* FIRST North Carolina State Championship (Cancelled)",
  NCGRE: "* FNC District ECU Event (Cancelled)",
  NCGUI: "* FNC District Guilford County Event (Cancelled)",
  NCPEM: "FNC District UNC Pembroke Event",
  NCWAK: "FNC District Wake County Event",
  NDGF: "Great Northern Regional",
  NECMP: "* New England District Championship (Cancelled)",
  NEWTON: "FIRST Championship - Houston - Newton Division (Cancelled)",
  NHGRS: "NE District Granite State Event",
  NHSNH: "* NE District Southern NH Event (Cancelled)",
  NJBRI: "* FMA District Bridgewater-Raritan Event (Cancelled)",
  NJFLA: "* FMA District Mount Olive Event (Cancelled)",
  NJROB: "* FMA District Robbinsville Event (Cancelled)",
  NJSKI: "* FMA District Montgomery Event (Cancelled)",
  NJTAB: "* FMA District Seneca Event (Cancelled)",
  NVLV: "* Las Vegas Regional (Cancelled)",
  NYLI: "* SBPLI Long Island Regional #1 (Cancelled)",
  NYLI2: "* SBPLI Long Island Regional #2 (Cancelled)",
  NYNY: "* New York City Regional (Cancelled)",
  NYRO: "* Finger Lakes Regional (Cancelled)",
  NYSU: "* Hudson Valley Regional (Cancelled)",
  NYTR: "* New York Tech Valley Regional (Cancelled)",
  NYUT: "* Central New York Regional (Cancelled)",
  OHCL: "* Buckeye Regional (Cancelled)",
  OHMV: "Miami Valley Regional",
  OKOK: "* Oklahoma Regional (Cancelled)",
  OKTU: "* Green Country Regional (Cancelled)",
  ONBAR: "ONT District Georgian College Event",
  ONCMP: "* FIRST Ontario Provincial Championship (Cancelled)",
  ONHAM: "* ONT District McMaster University Event (Cancelled)",
  ONLON:
    "* ONT District Western University, Western Engineering Event (Cancelled)",
  ONNOB: "* ONT District North Bay Event (Cancelled)",
  ONNYO: "* ONT District York University Event (Cancelled)",
  ONOSH: "ONT District Durham College Event",
  ONOTT: "* ONT District Carleton University Event (Cancelled)",
  ONTO1: "* ONT District Ryerson University Event (Cancelled)",
  ONTO3: "ONT District Humber College Event",
  ONWAT: "* ONT District University of Waterloo Event (Cancelled)",
  ONWIN: "* ONT District Windsor Essex Great Lakes Event (Cancelled)",
  ORORE: "PNW District Clackamas Academy Event",
  ORSAL: "* PNW District Oregon State Fairgrounds Event (Cancelled)",
  ORWIL: "* PNW District Wilsonville Event (Cancelled)",
  PABEN: "* FMA District Bensalem Event (Cancelled)",
  PACA: "* Greater Pittsburgh Regional (Cancelled)",
  PAHAT: "FMA District Hatboro-Horsham Event",
  PAPHI: "* FMA District Springside Chestnut Hill Academy Event (Cancelled)",
  PNCMP: "* Pacific Northwest FIRST District Championship (Cancelled)",
  QCMO: "* Festival de Robotique a Montreal Regional (Cancelled)",
  QCSH: "Festival de Robotique a Sherbrooke Regional",
  ROEBLING: "FIRST Championship - Houston - Roebling Division (Cancelled)",
  SCMB: "Palmetto Regional",
  TESLA: "FIRST Championship - Detroit - Tesla Division (Cancelled)",
  TNKN: "* Smoky Mountains Regional (Cancelled)",
  TNME: "* Memphis Regional (Cancelled)",
  TUIS: "Istanbul Regional",
  TUIS2: "Bosphorus Regional",
  TURING: "FIRST Championship - Houston - Turing Division (Cancelled)",
  TXAMA: "* FIT District Amarillo Event (Cancelled)",
  TXAUS: "* FIT District Austin Event (Cancelled)",
  TXCHA: "FIT District Channelview Event",
  TXDEL: "FIT District Del Rio Event",
  TXDLS: "* FIT District Dallas Event (Cancelled)",
  TXDRI: "FIT District Dripping Springs Event",
  TXELP: "* FIT District El Paso Event (Cancelled)",
  TXFOR: "* FIT District Fort Worth Event (Cancelled)",
  TXGRE: "FIT District Greenville Event",
  TXHOU: "* FIT District Houston Event (Cancelled)",
  TXNEW: "* FIT District New Braunfels Event (Cancelled)",
  TXPAS: "* FIT District Pasadena Event (Cancelled)",
  TXPLA: "FIT District Plano Event",
  UTWV: "Utah Regional",
  VABLA: "* CHS District Blacksburg VA Event (Cancelled)",
  VAGLE: "CHS District Richmond VA Event sponsored by Dominion Energy",
  VAHAY: "CHS District Haymarket VA Event",
  VAPOR:
    "* CHS District Portsmouth VA Event - See Site Info Document (Cancelled)",
  WAAHS: "* PNW District Auburn Event (Cancelled)",
  WAAMV:
    "* PNW District Auburn Mountainview Event - See Site Info Document (Cancelled)",
  WABEL: "* PNW District Bellingham Event (Cancelled)",
  WASNO: "PNW District Glacier Peak Event",
  WASPO: "PNW District West Valley Event",
  WAYAK: "* PNW District SunDome Event (Cancelled)",
  WEEK0: "Week 0",
  WILA: "* Seven Rivers Regional (Cancelled)",
  WIMI: "* Wisconsin Regional (Cancelled)",
};

const events2021 = {
  "21CHI": "2021 Season Participation for China Teams",
  "21CHS": "2021 Season Participation for CHS District Teams",
  "21FIM": "2021 Season Participation for FIM District Teams",
  "21FIN": "2021 Season Participation for FIN District Teams",
  "21FIT": "2021 Season Participation for FIT District Teams",
  "21FMA": "2021 Season Participation for FMA District Teams",
  "21FNC": "2021 Season Participation for FNC District Teams",
  "21ISR": "2021 Season Participation for ISR District Teams",
  "21MEX": "2021 Season Participation for Mexico Teams",
  "21NED": "2021 Season Participation for NE District Teams",
  "21ONT": "2021 Season Participation for ONT District Teams",
  "21PCH": "2021 Season Participation for PCH Teams",
  "21PNW": "2021 Season Participation for PNW District Teams",
  "21REG": "2021 Season Participation for Regional Teams",
  CAEAW: "Central Asia, Africa, & Europe Region Awards Event",
  CHIAW: "China Region Awards Event",
  CHSAW: "FIRST Chesapeake District Awards Event",
  CMPAW: "FIRST Robotics Competition Championship Level Awards Only Event",
  CSAAW: "Caribbean & South American Region Awards Event",
  FIMAW: "FIRST in Michigan District Awards Event",
  FINAW: "FIRST Indiana District Awards Event",
  FITAW: "FIRST in Texas District Awards Event",
  FLOAW: "Florida Region Awards Event",
  FMAAW: "FIRST Mid-Atlantic District Awards Event",
  FNCAW: "FIRST North Carolina District Awards Event",
  GAMAL: "Aluminium Group - Game Design Challenge",
  GAMAR: "Argon Group - Game Design Challenge",
  GAMAS: "Arsenic Group - Game Design Challenge",
  GAMB: "Boron Group - Game Design Challenge",
  GAMBE: "Beryllium Group - Game Design Challenge",
  GAMBR: "Bromine Group - Game Design Challenge",
  GAMC: "Carbon Group - Game Design Challenge",
  GAMCA: "Calcium Group - Game Design Challenge",
  GAMCE: "Game Design Challenge Event",
  GAMCL: "Chlorine Group - Game Design Challenge",
  GAMCO: "Cobalt Group - Game Design Challenge",
  GAMCR: "Chromium Group - Game Design Challenge",
  GAMCU: "Copper Group - Game Design Challenge",
  GAMF: "Fluorine Group - Game Design Challenge",
  GAMFE: "Iron Group - Game Design Challenge",
  GAMGA: "Gallium Group - Game Design Challenge",
  GAMGE: "Germanium Group - Game Design Challenge",
  GAMH: "Hydrogen Group - Game Design Challenge",
  GAMHE: "Helium Group - Game Design Challenge",
  GAMK: "Potassium Group - Game Design Challenge",
  GAMKR: "Krypton Group - Game Design Challenge",
  GAMLI: "Lithium Group - Game Design Challenge",
  GAMMG: "Magnesium Group - Game Design Challenge",
  GAMMN: "Manganese Group - Game Design Challenge",
  GAMMO: "Molybdenum Group - Game Design Challenge",
  GAMN: "Nitrogen Group - Game Design Challenge",
  GAMNA: "Sodium Group - Game Design Challenge",
  GAMNB: "Niobium Group - Game Design Challenge",
  GAMNE: "Neon Group - Game Design Challenge",
  GAMNI: "Nickel Group - Game Design Challenge",
  GAMO: "Oxygen Group - Game Design Challenge",
  GAMP: "Phosphorus Group - Game Design Challenge",
  GAMPD: "Palladium Group - Game Design Challenge",
  GAMRB: "Rubidium Group - Game Design Challenge",
  GAMRH: "Rhodium Group - Game Design Challenge",
  GAMRU: "Ruthenium Group - Game Design Challenge",
  GAMS: "Sulfur Group - Game Design Challenge",
  GAMSC: "Scandium Group - Game Design Challenge",
  GAMSE: "Selenium Group - Game Design Challenge",
  GAMSI: "Silicon Group - Game Design Challenge",
  GAMSR: "Strontium Group - Game Design Challenge",
  GAMTC: "Technetium Group - Game Design Challenge",
  GAMTI: "Titanium Group - Game Design Challenge",
  GAMV: "Vanadium Group - Game Design Challenge",
  GAMY: "Yttrium Group - Game Design Challenge",
  GAMZN: "Zinc Group - Game Design Challenge",
  GAMZR: "Zirconium Group - Game Design Challenge",
  GCVAW: "Greater Central Valley Region Awards Event",
  GLAAW: "Greater Los Angeles Region Awards Event",
  GSDAW: "Greater San Diego Region Awards Event",
  GSFAW: "Greater San Francisco Bay Region Awards Event",
  ILLAW: "Illinois Region Awards Event",
  INNAL: "Aluminium Group - FIRST Innovation Challenge",
  INNAR: "Argon Group - FIRST Innovation Challenge",
  INNB: "Boron Group - FIRST Innovation Challenge",
  INNBE: "Beryllium Group - FIRST Innovation Challenge",
  INNC: "Carbon Group - FIRST Innovation Challenge",
  INNCA: "Calcium Group - FIRST Innovation Challenge",
  INNCE: "FIRST Innovation Challenge Event presented by Qualcomm",
  INNCL: "Chlorine Group - FIRST Innovation Challenge",
  INNCO: "Cobalt Group - FIRST Innovation Challenge",
  INNCR: "Chromium Group - FIRST Innovation Challenge",
  INNCU: "Copper Group - FIRST Innovation Challenge",
  INNF: "Fluorine Group - FIRST Innovation Challenge",
  INNFE: "Iron Group - FIRST Innovation Challenge",
  INNH: "Hydrogen Group - FIRST Innovation Challenge",
  INNHE: "Helium Group - FIRST Innovation Challenge",
  INNK: "Potassium Group - FIRST Innovation Challenge",
  INNLI: "Lithium Group - FIRST Innovation Challenge",
  INNMG: "Magnesium Group - FIRST Innovation Challenge",
  INNMN: "Manganese Group - FIRST Innovation Challenge",
  INNN: "Nitrogen Group - FIRST Innovation Challenge",
  INNNA: "Sodium Group - FIRST Innovation Challenge",
  INNNE: "Neon Group - FIRST Innovation Challenge",
  INNNI: "Nickel Group - FIRST Innovation Challenge",
  INNO: "Oxygen Group - FIRST Innovation Challenge",
  INNP: "Phosphorus Group - FIRST Innovation Challenge",
  INNS: "Sulfur Group - FIRST Innovation Challenge",
  INNSC: "Scandium Group - FIRST Innovation Challenge",
  INNSI: "Silicon Group - FIRST Innovation Challenge",
  INNTI: "Titanium Group - FIRST Innovation Challenge",
  INNV: "Vanadium Group - FIRST Innovation Challenge",
  INNZN: "Zinc Group - FIRST Innovation Challenge",
  IRHAG: "Silver Group - INFINITE RECHARGE At Home Challenge",
  IRHAL: "Aluminium Group - INFINITE RECHARGE At Home Challenge",
  IRHAR: "Argon Group - INFINITE RECHARGE At Home Challenge",
  IRHAS: "Arsenic Group - INFINITE RECHARGE At Home Challenge",
  IRHB: "Boron Group - INFINITE RECHARGE At Home Challenge",
  IRHBA: "Barium Group - INFINITE RECHARGE At Home Challenge",
  IRHBE: "Beryllium Group - INFINITE RECHARGE At Home Challenge",
  IRHBR: "Bromine Group - INFINITE RECHARGE At Home Challenge",
  IRHC: "Carbon Group - INFINITE RECHARGE At Home Challenge",
  IRHCA: "Calcium Group - INFINITE RECHARGE At Home Challenge",
  IRHCD: "Cadmium Group - INFINITE RECHARGE At Home Challenge",
  IRHCE: "INFINITE RECHARGE At Home Challenge Event",
  IRHCL: "Chlorine Group - INFINITE RECHARGE At Home Challenge",
  IRHCO: "Cobalt Group - INFINITE RECHARGE At Home Challenge",
  IRHCR: "Chromium Group - INFINITE RECHARGE At Home Challenge",
  IRHCS: "Caesium Group - INFINITE RECHARGE At Home Challenge",
  IRHCU: "Copper Group - INFINITE RECHARGE At Home Challenge",
  IRHF: "Fluorine Group - INFINITE RECHARGE At Home Challenge",
  IRHFE: "Iron Group - INFINITE RECHARGE At Home Challenge",
  IRHGA: "Gallium Group - INFINITE RECHARGE At Home Challenge",
  IRHGE: "Germanium Group - INFINITE RECHARGE At Home Challenge",
  IRHH: "Hydrogen Group - INFINITE RECHARGE At Home Challenge",
  IRHHE: "Helium Group - INFINITE RECHARGE At Home Challenge",
  IRHI: "Iodine Group - INFINITE RECHARGE At Home Challenge",
  IRHIN: "Indium Group - INFINITE RECHARGE At Home Challenge",
  IRHK: "Potassium Group - INFINITE RECHARGE At Home Challenge",
  IRHKR: "Krypton Group - INFINITE RECHARGE At Home Challenge",
  IRHLA: "Lanthanum Group - INFINITE RECHARGE At Home Challenge",
  IRHLI: "Lithium Group - INFINITE RECHARGE At Home Challenge",
  IRHMG: "Magnesium Group - INFINITE RECHARGE At Home Challenge",
  IRHMN: "Manganese Group - INFINITE RECHARGE At Home Challenge",
  IRHMO: "Molybdenum Group - INFINITE RECHARGE At Home Challenge",
  IRHN: "Nitrogen Group - INFINITE RECHARGE At Home Challenge",
  IRHNA: "Sodium Group - INFINITE RECHARGE At Home Challenge",
  IRHNB: "Niobium Group - INFINITE RECHARGE At Home Challenge",
  IRHND: "Neodymium Group - INFINITE RECHARGE At Home Challenge",
  IRHNE: "Neon Group - INFINITE RECHARGE At Home Challenge",
  IRHNI: "Nickel Group - INFINITE RECHARGE At Home Challenge",
  IRHO: "Oxygen Group - INFINITE RECHARGE At Home Challenge",
  IRHP: "Phosphorus Group - INFINITE RECHARGE At Home Challenge",
  IRHPD: "Palladium Group - INFINITE RECHARGE At Home Challenge",
  IRHPM: "Promethium Group - INFINITE RECHARGE At Home Challenge",
  IRHPR: "Praseodymium Group - INFINITE RECHARGE At Home Challenge",
  IRHRB: "Rubidium Group - INFINITE RECHARGE At Home Challenge",
  IRHRH: "Rhodium Group - INFINITE RECHARGE At Home Challenge",
  IRHRU: "Ruthenium Group - INFINITE RECHARGE At Home Challenge",
  IRHS: "Sulfur Group - INFINITE RECHARGE At Home Challenge",
  IRHSB: "Antimony Group - INFINITE RECHARGE At Home Challenge",
  IRHSC: "Scandium Group - INFINITE RECHARGE At Home Challenge",
  IRHSE: "Selenium Group - INFINITE RECHARGE At Home Challenge",
  IRHSI: "Silicon Group - INFINITE RECHARGE At Home Challenge",
  IRHSM: "Samarium Group - INFINITE RECHARGE At Home Challenge",
  IRHSN: "Tin Group - INFINITE RECHARGE At Home Challenge",
  IRHSR: "Strontium Group - INFINITE RECHARGE At Home Challenge",
  IRHTC: "Technetium Group - INFINITE RECHARGE At Home Challenge",
  IRHTE: "Tellurium Group - INFINITE RECHARGE At Home Challenge",
  IRHTI: "Titanium Group - INFINITE RECHARGE At Home Challenge",
  IRHV: "Vanadium Group - INFINITE RECHARGE At Home Challenge",
  IRHXE: "Xenon Group - INFINITE RECHARGE At Home Challenge",
  IRHY: "Yttrium Group - INFINITE RECHARGE At Home Challenge",
  IRHZN: "Zinc Group - INFINITE RECHARGE At Home Challenge",
  IRHZR: "Zirconium Group - INFINITE RECHARGE At Home Challenge",
  ISRAW: "FIRST Israel District Awards Event",
  MEXAW: "Mexico Region Awards Event",
  MOKAW: "MoKan Region Awards Event",
  MPLAW: "Midwestern Plains Region Awards Event",
  NEFAW: "NE FIRST District Awards Event",
  NYQAW: "New York & Quebec Region Awards Event",
  ONTAW: "FIRST Ontario District Awards Event",
  PACAW: "Pacific Region Awards Event",
  PCHAW: "Peachtree District Awards Event",
  PNWAW: "Pacific Northwest District Awards Event",
  RMTAW: "Rocky Mountain Region Awards Event",
  SOAAW: "Southern Appalachian Region Awards Event",
  SOEAW: "Southeastern Region Awards Event",
  SOWAW: "Southwestern Region Awards Event",
  TURAW: "Turkish Region Awards Event",
  UMDAW: "Upper Midwest Region Awards Event",
  UORAW: "Upper Ohio River Region Awards Event",
  WISAW: "Wisconsin Region Awards Event",
};

const events2022 = {
  ALHU: "Rocket City Regional",
  ARLI: "Arkansas Regional presented by Harding University and Searcy A & P",
  ARSEA: "Ozark Mountain Brawl",
  AUSC: "Southern Cross Regional",
  AZFL: "Arizona North Regional",
  AZGL2: "Arizona Robotics League Qualifier 2",
  AZGLE: "Arizona Robotics League Qualifier 1",
  AZTEM: "Sanghi Foundation AZ State 2022 FIRST Robotics Competition",
  AZVA: "Arizona Valley Regional",
  BCVI: "Canadian Pacific Regional",
  CAAV: "Aerospace Valley Regional",
  CADA: "Sacramento Regional",
  CAELK: "Capital City Classic",
  CAFR: "Central Valley Regional",
  CALA: "Los Angeles Regional",
  CAMB: "Monterey Bay Regional",
  CAOC: "Orange County Regional",
  CAPH: "Hueneme Port Regional",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  CASJ: "Silicon Valley Regional",
  CAVE: "Ventura County Regional",
  CHCMP:
    "FIRST Chesapeake District Championship sponsored by Newport News Shipbuilding",
  CMPTX: "FIRST Championship - FIRST Robotics Competition",
  CNE: "Robots@CNE",
  CODE: "Colorado Regional",
  CODEN: "2022 Kendrick Castillo Memorial Tournament",
  CTHAR: "NE District Hartford Event",
  CTWAT: "NE District Waterbury Event",
  DC1SE: "CHS District Greater DC Series #1",
  DC2SE: "CHS District Greater DC Series #2",
  DC305: "CHS District Greater DC Event #1 Day 1",
  DC306: "CHS District Greater DC Event #1 Day 2",
  DC312: "CHS District Greater DC Event #2 Day 1",
  DC313: "CHS District Greater DC Event #2 Day 2",
  DC319: "CHS District Greater DC Event #4 Day 1",
  DC320: "CHS District Greater DC Event #4 Day 2",
  DC326: "CHS District Greater DC Event #3 Day 1",
  DC327: "CHS District Greater DC Event #3 Day 2",
  DC3SE: "CHS District Greater DC Series #3",
  DC4SE: "CHS District Greater DC Series #4",
  FLCRE: "Emerald Coast Robotics and Innovation Demonstration",
  FLMEL: "Space Coast Showdown",
  FLOR: "Orlando Regional",
  FLTA: "Tallahassee Regional",
  FLTAM: "ROBOTICON",
  FLWP: "South Florida Regional",
  GAALB: "PCH District Albany Event",
  GACAR: "PCH District Carrollton Event",
  GACMP: "Peachtree District State Championship",
  GACOL: "PCH District Columbus Event",
  GADAL: "PCH District Dalton Event",
  GAGAI: "GRITS (Georgia Robotics Invitational Tournament & Showcase) 2022",
  HIHO: "Hawaii Regional",
  IACF: "Iowa Regional",
  IDBO: "Idaho Regional",
  ILCH: "Midwest Regional",
  ILPE: "Central Illinois Regional",
  ILTRE: "Roboteer Rumble 2022",
  INCMP: "FIRST Indiana State Championship",
  INCO2: "Indiana Robotics Invitational",
  INCOL: "FIN District Columbus Event presented by Toyota",
  INKOK: "FIN District Kokomo Event presented by AndyMark",
  INWLA: "FIN District Tippecanoe Event",
  ISCMP: "FIRST Israel District Championship",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  KSLA: "Heartland Regional",
  LADEN: "The DOW Red Stick Rumble",
  LAKE: "Bayou Regional presented by Entergy",
  LAMAN: "Northshore Knockout",
  MABIL: "N.E.R.D. - New England Robotics Derby",
  MABOS: "NE District Greater Boston Event",
  MACMA: "NE District Central Mass Event",
  MAREA: "NE District North Shore Event",
  MAWNE: "NE District Western NE Event",
  MAWO2: "BattleCry at WPI 22",
  MAWOR: "NE District WPI Event",
  MDLU2: "Battle of Baltimore 1",
  MDLUT: "Battle of Baltimore 2",
  MEFAL: "Summer Heat",
  MELEW: "NE District Pine Tree Event",
  MIBEL: "FIM District Belleville Event",
  MIBRO: "Goonettes Invitational",
  MICMP: "FIRST in Michigan State Championship",
  MIDET: "FIM District Detroit Event",
  MIESC: "FIM District Escanaba Event",
  MIFOR: "FIM District Calvin University Event",
  MIGUL: "FIM District Gull Lake Event presented by Parker Hannifin",
  MIJAC: "FIM District Jackson Event presented by Spring Arbor and Consumers",
  MIKE2: "FIM District Kettering University Event #2 presented by Ford",
  MIKEN: "FIM District Kentwood Event presented by Dematic",
  MIKET: "FIM District Kettering University Event #1",
  MIKNG: "FIM District Kingsford Event",
  MILAK: "FIM District Lakeview Event",
  MILAN: "FIM District Lansing Event",
  MILIV: "FIM District Livonia Event presented by Aisin",
  MIMCC:
    "FIM District Macomb Community College Event presented by DTE Foundation",
  MIMID: "FIM District Midland Event presented by Dow",
  MIMIL: "FIM District Milford Event presented by GM Proving Grounds",
  MIMUS: "FIM District Muskegon Event presented by Renk",
  MIROC: "FIM District Rochester Event presented by Aptiv",
  MISAL: "FIM District Saline Event presented by DTE",
  MISJO: "FIM District St. Joseph Event presented by Whirlpool",
  MITRY: "FIM District Troy Event presented by Magna",
  MITVC: "FIM District Traverse City Event",
  MIWAL: "FIM District Walled Lake Event presented by Magna",
  MIWMI: "FIM District West Michigan Event",
  MIWOO: "FIM District Woodhaven Event presented by Ford",
  MIWYO: "Grand Rapids Girls Robotics Competition",
  MNDU: "Lake Superior Regional",
  MNDU2: "Northern Lights Regional",
  MNMI: "Minnesota 10,000 Lakes Regional presented by Medtronic",
  MNMI2: "Minnesota North Star Regional",
  MNMIN: "Minnesota State High School League Championship",
  MNROS: "Minnesota Robotics Invitational",
  MOKC: "Greater Kansas City Regional",
  MOKC3: "Central Missouri Regional",
  MOSL: "St. Louis Regional",
  MOSTC: "Gateway Robotics Challenge (GRC)",
  MRCMP: "FIRST Mid-Atlantic District Championship",
  MXMO: "Regional Monterrey",
  MXTO: "Regional Laguna",
  NCASH: "FNC District UNC Asheville Event",
  NCCH2: "Doyenne Inspiration",
  NCCHA: "THOR 3",
  NCCMP: "FIRST North Carolina District State Championship",
  NCGRE: "FNC District ECU Event",
  NCGUI: "FNC District Guilford County Event",
  NCMT2: "THOR 1.2",
  NCPEM: "FNC District UNC Pembroke Event",
  NDGF: "Great Northern Regional",
  NECMP: "New England FIRST District Championship",
  NHALT: "Battle Of the Bay",
  NHGRS: "NE District Granite State Event",
  NHMAN: "RiverRage 25",
  NHMER: "Mayhem in Merrimack",
  NHSEA: "NE District Pease ANG Event",
  NJBR2: "Raritan Valley Robot Rumble",
  NJBRI: "FMA District Bridgewater-Raritan Event",
  NJCAR: "Duel on the Delaware",
  NJCLI: "Mustang Robot Rodeo",
  NJFLA: "FMA District Mount Olive Event",
  NJNOR: "Brunswick Eruption",
  NJSKI: "FMA District Montgomery Event",
  NJTAB: "FMA District Seneca Event",
  NVLV: "Las Vegas Regional",
  NYBA2: "Robot Rumble",
  NYBAL: "Robot Rumble",
  NYLI: "SBPLI Long Island Regional #1",
  NYLI2: "SBPLI Long Island Regional #2",
  NYNEW: "City College Off Season",
  NYNY: "New York City Regional",
  NYRO: "Finger Lakes Regional",
  NYROC: "RahChaCha Ruckus",
  NYTR: "New York Tech Valley Regional",
  OHCL: "Buckeye Regional",
  OKOK: "Oklahoma Regional",
  OKTU: "Green Country Regional",
  ON034: "ONT District North Bay Event Day 2",
  ON204: "ONT District Windsor Essex Great Lakes Event Day 2",
  ON263: "ONT District York University Event Day 1",
  ON273: "ONT District York University Event Day 2",
  ON305: "ONT District Humber College Event Day 1",
  ON306: "ONT District Humber College Event Day 2",
  ON325: "ONT District University of Waterloo Event Day 1",
  ON326: "ONT District University of Waterloo Event Day 2",
  ON409: "ONT District St. Mary C.S.S. Event Day 1",
  ON410: "ONT District St. Mary C.S.S. Event Day 2",
  ONCMP: "FIRST Ontario Provincial Championship",
  ONCMP1: "FIRST Ontario Provincial Championship - Technology Division",
  ONCMP2: "FIRST Ontario Provincial Championship - Science Division",
  ONHCS: "ONT District Humber College Series",
  ONNBS: "ONT District North Bay-Windor Essex Series",
  ONSMS: "ONT District St. Mary C.S.S. Series",
  ONUWS: "ONT District University of Waterloo Series",
  ONYUS: "ONT District York University Series",
  ORORE: "PNW District Clackamas Academy Event",
  ORSAL: "PNW District Oregon State Fairgrounds Event",
  ORWI2: "OR Girls Generation",
  ORWIL: "PNW District Wilsonville Event",
  OS189: "Robot Rumble",
  OS342: "Arizona Robotics League Qualifier 3",
  OS417: "Robot Rumble",
  OS454: "Arizona Robotics League Qualifier 4",
  PAAMB: "Ramp Riot",
  PABEN: "FMA District Bensalem Event",
  PACA: "Greater Pittsburgh Regional",
  PAFLO: "GirlPOWER",
  PAHAT: "FMA District Hatboro-Horsham Event",
  PAHOR: "HAVOC 2022",
  PAPHI: "FMA District Springside Chestnut Hill Academy Event",
  PNCMP: "Pacific Northwest FIRST District Championship",
  QCMO1: "Festival de Robotique Regional Day 1",
  QCMO2: "Festival de Robotique Regional Day 2",
  QCMO3: "Festival de Robotique Regional Day 3",
  QCMOS: "Festival de Robotique Series",
  SCAN: "Electric City Regional",
  SCCOL: "SCRIW XI",
  TANT: "New Taipei City x Hon Hai Regional",
  TNKN: "Smoky Mountains Regional",
  TUIS: "Istanbul Regional",
  TUIS2: "Bosphorus Regional",
  TUIS3: "Izmir Regional",
  TXAMA: "FIT District Amarillo Event",
  TXAUS: "FIT District Austin Event",
  TXCHA: "FIT District Channelview Event",
  TXCMP: "FIRST In Texas District Championship",
  TXDRI: "FIT District Dripping Springs Event",
  TXFOR: "FIT District Fort Worth Event",
  TXHOU: "Texas Robotics Invitational",
  TXIR2: "The Texas Cup 2022",
  TXIRV: "FIT District Irving Event",
  TXPA2: "FIT District Pasadena Event #2",
  TXPAS: "FIT District Pasadena Event #1",
  TXRO2: "StemGals",
  TXROC: "NTX Tournament of Robots 2022",
  TXTHE: "The Remix",
  TXWAC: "FIT District Waco Event",
  UTWV: "Utah Regional",
  VA1SE: "CHS District Greater Richmond Series #1",
  VA2SE: "CHS District Greater Richmond Series #2",
  VA305: "CHS District Greater Richmond Event #1 Day 1",
  VA306: "CHS District Greater Richmond Event #1 Day 2",
  VA319: "CHS District Greater Richmond Event #2 Day 1",
  VA320: "CHS District Greater Richmond Event #2 Day 2",
  VANEW: "Rumble in the Roads",
  WAAHS: "PNW District Auburn Event",
  WABEL: "Bordie React",
  WABON: "PNW District Bonney Lake Event",
  WAMAP: "Washington Girls Generation",
  WAMIL: "PNW Block Party",
  WASAM: "PNW District Sammamish Event",
  WASNO: "PNW District Glacier Peak Event",
  WASPO: "PNW District West Valley Event",
  WAYAK: "PNW District SunDome Event",
  WEEK0: "Week 0",
  WILA: "Seven Rivers Regional",
  WIMI: "Wisconsin Regional",
  WVMOR: "WVRoX 2022",
  XXHA2: "Overtime 2022",
  XXHAM: "STEMley Cup 2022",
  XXIST: "2022 Pendik Off Season",
  XXMA2: "Rapid React #2",
  XXMAC: "Rapid React #1",
  XXMEL: "Melbourne Robotics Tournament (MRT 2022)",
  XXRIO: "FIRST ROBOTICS COMPETITION OFF-SEASON BRAZIL",
  ZHHA: "Hangzhou Regional",
  CARVER: "FIRST Championship - Carver Division",
  GALILEO: "FIRST Championship - Galileo Division",
  HOPPER: "FIRST Championship - Hopper Division",
  NEWTON: "FIRST Championship - Newton Division",
  ROEBLING: "FIRST Championship - Roebling Division",
  TURING: "FIRST Championship - Turing Division",
  TXCMP1: "FIRST In Texas District Championship - Apollo Division",
  TXCMP2: "FIRST In Texas District Championship - Mercury Division",
  MICMP3: "FIRST in Michigan State Championship - APTIV Division",
  MICMP4: "FIRST in Michigan State Championship - Consumers Energy Division",
  MICMP1:
    "FIRST in Michigan State Championship - DTE Energy Foundation Division",
  MICMP2: "FIRST in Michigan State Championship - Ford Division",
  NECMP1: "New England FIRST District Championship - Calcium Division",
  NECMP2: "New England FIRST District Championship - Titanium Division",
};

const events2023 = {
  CAAV: "Aerospace Valley Regional",
  AZVA: "Arizona East Regional",
  AZGL: "Arizona West Regional",
  ARLI: "Arkansas Regional",
  LAKE: "Bayou Regional",
  TUIS2: "Bosphorus Regional",
  BRBR: "Brazil Regional",
  OHCL: "Buckeye Regional",
  VAALE: "CHS District Alexandria VA Event",
  MDBET: "CHS District Bethesda MD Event",
  VABLA: "CHS District Blacksburg VA Event",
  VAGLE: "CHS District Glen Allen VA Event",
  VAPOR: "CHS District Portsmouth VA Event",
  MDTIM: "CHS District Timonium MD Event",
  BCVI: "Canadian Pacific Regional",
  ILPE: "Central Illinois Regional",
  MOSE: "Central Missouri Regional",
  CAFR: "Central Valley Regional",
  CODE: "Colorado Regional",
  MIBEL: "FIM District Belleville Event presented by Belleville Yacht Club",
  MIFOR: "FIM District Calvin University Event presented by Amway",
  MIDET: "FIM District Detroit Event presented by DTE",
  MIESC: "FIM District Escanaba Event presented by Highline Fast",
  MIJAC: "FIM District Jackson Event presented by Consumers Energy Foundation",
  MIKEN: "FIM District Kentwood Event presented by Dematic",
  MIKET: "FIM District Kettering University Event #1 presented by Ford",
  MIKE2: "FIM District Kettering University Event #2 presented by Ford",
  MILSU: "FIM District LSSU Event",
  MILAK: "FIM District Lakeview Event #1 presented by Parker-Hannifin",
  MILA2: "FIM District Lakeview Event #2",
  MILAN: "FIM District Lansing Event",
  MILIV: "FIM District Livonia Event presented by Aisin",
  MIMCC: "FIM District Macomb Community College Event presented by DTE",
  MIMID: "FIM District Midland Event presented by Dow",
  MIMIL: "FIM District Milford Event presented by GM Proving Grounds",
  MIMUS: "FIM District Muskegon Event presented by RENK",
  MISAL: "FIM District Saline Event",
  MISJO: "FIM District St. Joseph Event presented by Whirlpool Corporation",
  MISTA: "FIM District Standish-Sterling Event",
  MITVC: 'FIM District Traverse City Event presented by "Cone Drive by Timken"',
  MITRY: "FIM District Troy Event #1 presented by Aptiv",
  MITR2: "FIM District Troy Event #2 presented by Magna",
  MIDTR: "FIM District Wayne State University Event presented by Magna",
  MIWMI: "FIM District West Michigan Event",
  INGRE: "FIN District Greenwood Event",
  INMIS: "FIN District Mishawaka Event",
  INPRI: "FIN District Princeton Event presented by Toyota",
  INWLA: "FIN District Tippecanoe Event",
  ARPKY:
    "FIRST Championship - Archimedes Division presented by Kettering University",
  CPRA: "FIRST Championship - Curie Division presented by Rockwell Automation",
  DCMP: "FIRST Championship - Daly Division",
  CMPTX: "FIRST Championship - FIRST Robotics Competition",
  GCMP: "FIRST Championship - Galileo Division",
  HCMP: "FIRST Championship - Hopper Division",
  JCMP: "FIRST Championship - Johnson Division",
  MPCIA: "FIRST Championship - Milstein Division presented by CIA",
  NPFCMP: "FIRST Championship - Newton Division presented by Ford",
  CHCMP: "FIRST Chesapeake District Championship",
  TXCMP: "FIRST In Texas District Championship presented by Phillips 66",
  TXCMP1:
    "FIRST In Texas District Championship presented by Phillips 66 - APOLLO Division",
  TXCMP2:
    "FIRST In Texas District Championship presented by Phillips 66 - MERCURY Division",
  INCMP: "FIRST Indiana State Championship",
  ISCMP: "FIRST Israel District Championship",
  NYLI: "FIRST Long Island Regional #1",
  NYLI2: "FIRST Long Island Regional #2",
  MRCMP: "FIRST Mid-Atlantic District Championship",
  NCCMP: "FIRST North Carolina District State Championship",
  ONCMP: "FIRST Ontario Provincial Championship",
  ONCMP2: "FIRST Ontario Provincial Championship - SCIENCE Division",
  ONCMP1: "FIRST Ontario Provincial Championship - TECHNOLOGY Division",
  MICMP: "FIRST in Michigan State Championship presented by DTE Foundation",
  MICMP3:
    "FIRST in Michigan State Championship presented by DTE Foundation - APTIV Division",
  MICMP4:
    "FIRST in Michigan State Championship presented by DTE Foundation - CONSUMERS ENERGY Division",
  MICMP1:
    "FIRST in Michigan State Championship presented by DTE Foundation - DTE ENERGY FOUNDATION Division",
  MICMP2:
    "FIRST in Michigan State Championship presented by DTE Foundation - FORD Division",
  TXAMA: "FIT District Amarillo Event",
  TXBEL: "FIT District Belton Event",
  TXCHA: "FIT District Channelview Event",
  TXDAL: "FIT District Dallas Event",
  TXFOR: "FIT District Fort Worth Event",
  TXHOU: "FIT District Houston Event",
  TXSAN: "FIT District San Antonio Event",
  TXCLE: "FIT District Space City @ Clear Lake Event",
  TXWAC: "FIT District Waco Event",
  PABEN: "FMA District Bensalem Event",
  PAHAT: "FMA District Hatboro-Horsham Event",
  NJSKI: "FMA District Montgomery Event",
  NJFLA: "FMA District Mount Olive Event",
  NJROB: "FMA District Robbinsville Event",
  NJTAB: "FMA District Seneca Event",
  PAPHI: "FMA District Springside Chestnut Hill Academy Event",
  NJWAS: "FMA District Warren Hills Event",
  NCJOH: "FNC District Johnston County Event",
  NCMEC: "FNC District Mecklenburg County Event",
  NCASH: "FNC District UNC Asheville Event",
  NCPEM: "FNC District UNC Pembroke Event",
  NCWAK: "FNC District Wake County Event",
  QCMO: "Festival de Robotique Regional",
  NYRO: "Finger Lakes Regional",
  NDGF: "Great Northern Regional",
  MOKC: "Greater Kansas City Regional",
  PACA: "Greater Pittsburgh Regional presented by Argo AI",
  OKTU: "Green Country Regional",
  TUHC: "Halic Regional",
  HIHO: "Hawaii Regional",
  KSLA: "Heartland Regional",
  CAPH: "Hueneme Port Regional",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  IDBO: "Idaho Regional",
  IACF: "Iowa Regional",
  TUIS: "Istanbul Regional",
  TUIS3: "Izmir Regional",
  MNDU: "Lake Superior Regional",
  NVLV: "Las Vegas Regional",
  CALA: "Los Angeles Regional",
  MSLR: "Magnolia Regional",
  OHMV: "Miami Valley Regional",
  ILCH: "Midwest Regional",
  MNMI: "Minnesota 10,000 Lakes Regional presented by Medtronic",
  MNMI2: "Minnesota North Star Regional at La Crosse",
  CAMB: "Monterey Bay Regional",
  NHGRS: "NE District Granite State Event",
  MABOS: "NE District Greater Boston Event",
  CTHAR: "NE District Hartford Event",
  MAREA: "NE District North Shore Event",
  RINSC: "NE District Rhode Island Event",
  MABRI: "NE District SE Mass Event",
  NHDUR: "NE District UNH Event",
  MAWOR: "NE District WPI Event",
  CTWAT: "NE District Waterbury Event",
  MAWNE: "NE District Western NE Event",
  NECMP: "New England FIRST District Championship",
  NECMP1: "New England FIRST District Championship - MEIR Division",
  NECMP2: "New England FIRST District Championship - WILSON Division",
  NYNY: "New York City Regional",
  NYTR: "New York Tech Valley Regional",
  MNDU2: "Northern Lights Regional",
  ONBAR: "ONT District Georgian Event",
  ONTOR: "ONT District Humber College Event",
  ONHAM: "ONT District McMaster University",
  ONNEW: "ONT District Newmarket Complex Event",
  ONNOB: "ONT District North Bay Event",
  ONWAT: "ONT District University of Waterloo Event",
  ONLON: "ONT District Western University Engineering Event",
  ONWIN: "ONT District Windsor Essex Great Lakes Event",
  OKOK: "Oklahoma Regional",
  CAOC: "Orange County Regional",
  FLOR: "Orlando Regional",
  GAALB: "PCH District Albany Event",
  SCAND: "PCH District Anderson Event presented by Magna",
  GACAR: "PCH District Carrollton Event",
  GADAL: "PCH District Dalton Event",
  GAGWI: "PCH District Gwinnett Event",
  SCHAR:
    "PCH District Hartsville Event presented by South Carolina Department of Education",
  GAMAC: "PCH District Macon Event",
  WAAHS: "PNW District Auburn Event",
  WABON: "PNW District Bonney Lake Event",
  ORORE: "PNW District Clackamas Academy Event",
  WASNO: "PNW District Glacier Peak Event",
  ORSAL: "PNW District Oregon State Fairgrounds Event",
  WASAM: "PNW District Sammamish Event",
  WAYAK: "PNW District SunDome Event",
  ORWIL: "PNW District Wilsonville Event",
  PNCMP: "Pacific Northwest FIRST District Championship",
  GACMP: "Peachtree District Championship",
  MXTO: "Regional Laguna",
  MXMO: "Regional Monterrey",
  MXPU: "Regional Puebla",
  ALHU: "Rocket City Regional",
  CADA: "Sacramento Regional",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  WILA: "Seven Rivers Regional",
  CASJ: "Silicon Valley Regional",
  TNKN: "Smoky Mountains Regional",
  FLWP: "South Florida Regional",
  AUSC: "Southern Cross Regional",
  MOSL: "St. Louis Regional",
  FLTA: "Tallahassee Regional",
  UTWV: "Utah Regional",
  CAVE: "Ventura County Regional",
  WEEK0: "Week 0",
  WIMI: "Wisconsin Regional",
};

const events2024 = {
  CAAV: "Aerospace Valley Regional",
  AZGL: "Arizona East Regional",
  AZVA: "Arizona Valley Regional",
  ARLI: "Arkansas Regional",
  LAKE: "Bayou Regional",
  TUIS2: "Bosphorus Regional",
  BRBR: "Brazil Regional",
  OHCL: "Buckeye Regional",
  VAASH: "CHS District Ashland VA Event",
  VABLA: "CHS District Blacksburg VA Event",
  VAFAL: "CHS District Falls Church VA Event presented by Department of Energy",
  VAGLE: "CHS District Glen Allen VA Event",
  MDOWI: "CHS District Owings Mills MD Event",
  VAPOR: "CHS District Portsmouth VA Event",
  MDSEV: "CHS District Severn MD Event",
  BCVI: "Canadian Pacific Regional",
  ILPE: "Central Illinois Regional",
  MOSE: "Central Missouri Regional",
  CAFR: "Central Valley Regional",
  CODE: "Colorado Regional",
  CABE: "East Bay Regional",
  MIANN: "FIM District Ann Arbor Event presented by DTE",
  MIBAT: "FIM District Battle Creek Event presented by Parker Hannifin",
  MIBEL: "FIM District Belleville Event presented by Autokiniton",
  MIBER: "FIM District Berrien Springs Event presented by Edgewater Automation",
  MIMTP: "FIM District Central Michigan University Event",
  MIESC: "FIM District Escanaba Event presented by Highline",
  MIBKN:
    "FIM District Jackson at Columbia Event presented by Consumers Energy Foundation",
  MIKEN: "FIM District Kentwood Event presented by Dematic",
  MIKET:
    "FIM District Kettering University Event #1 presented by The Gene Haas Foundation",
  MIKE2:
    "FIM District Kettering University Event #2 presented by The Gene Haas Foundation",
  MILSU: "FIM District LSSU Event",
  MILAC: "FIM District Lake City Event",
  MILAN: "FIM District Lansing Event",
  MILIV: "FIM District Livonia Event presented by Aisin",
  MIMCC: "FIM District Macomb Event presented by DTE",
  MIMID: "FIM District Midland Event presented by Dow",
  MIMIL: "FIM District Milford Event presented by GM Milford Proving Ground",
  MIMUS: "FIM District Muskegon Event presented by Renk",
  MIDET: "FIM District Renaissance Event presented by DTE Energy Foundation",
  MISJO: "FIM District St. Joseph Event presented by Whirlpool",
  MITVC: "FIM District Traverse City Event presented by Cone Drive",
  MITRY: "FIM District Troy Event #1 presented by Aptiv",
  MITR2: "FIM District Troy Event #2 presented by Magna",
  MIDTR: "FIM District Wayne State Event presented by Magna",
  MIWMI: "FIM District West Michigan Event",
  MIBRO: "FIM District Woodhaven Event presented by Altair",
  INCOL: "FIN District Columbus Event",
  INMIS: "FIN District Mishawaka Event",
  INPLA: "FIN District Plainfield Event presented by Carrier",
  INEVA:
    "FIN District Washington Event presented by Toyota Indiana and Vincennes University",
  CMPTX: "FIRST Championship - FIRST Robotics Competition",
  CHCMP: "FIRST Chesapeake District Championship",
  TXCMP: "FIRST In Texas District Championship",
  INCMP: "FIRST Indiana State Championship",
  ISCMP: "FIRST Israel District Championship",
  NYLI2: "FIRST Long Island Regional",
  MRCMP: "FIRST Mid-Atlantic District Championship",
  NCCMP: "FIRST North Carolina District State Championship",
  ONCMP: "FIRST Ontario Provincial Championship",
  MICMP: "FIRST in Michigan State Championship",
  TXAMA: "FIT District Amarillo Event",
  TXBEL: "FIT District Belton Event",
  TXDAL: "FIT District Dallas Event",
  TXFOR: "FIT District Fort Worth Event",
  TXHOU: "FIT District Houston Event",
  TXKAT: "FIT District Katy Event",
  TXPLA: "FIT District Plano Event",
  TXSAN: "FIT District San Antonio Event",
  TXCLE: "FIT District Space City @ Friendswood Event",
  TXWAC: "FIT District Waco Event",
  NJALL: "FMA District Allentown Event",
  PABEN: "FMA District Bensalem Event",
  PAHAT: "FMA District Hatboro-Horsham Event",
  NJSKI: "FMA District Montgomery Event",
  NJFLA: "FMA District Mount Olive Event",
  NJTAB: "FMA District Seneca Event",
  PAPHI: "FMA District Springside Chestnut Hill Event",
  NJWAS: "FMA District Warren Hills Event",
  NCMEC: "FNC District Mecklenburg County Event",
  NCWAK: "FNC District Orange County Event",
  NCASH: "FNC District UNC Asheville Event",
  NCPEM: "FNC District UNC Pembroke Event",
  NCWA2: "FNC District Wake County Event",
  QCMO: "Festival de Robotique Regional",
  NYRO: "Finger Lakes Regional",
  NDGF: "Great Northern Regional",
  MOKC: "Greater Kansas City Regional",
  PACA: "Greater Pittsburgh Regional",
  OKTU: "Green Country Regional",
  TUHC: "Haliç Regional",
  HIHO: "Hawaii Regional",
  KSLA: "Heartland Regional",
  NYSU: "Hudson Valley Regional",
  CAPH: "Hueneme Port Regional",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  IDBO: "Idaho Regional",
  IACF: "Iowa Regional",
  MNDU: "Lake Superior Regional",
  NVLV: "Las Vegas Regional",
  CALA: "Los Angeles Regional",
  MSLR: "Magnolia Regional",
  TUIS3: "Marmara Regional",
  OHMV: "Miami Valley Regional",
  ILCH: "Midwest Regional",
  MNMI: "Minnesota 10,000 Lakes Regional",
  MNMI2: "Minnesota Granite City Regional",
  CAMB: "Monterey Bay Regional",
  MABRI: "NE District BSU Event",
  NHGRS: "NE District Granite State Event",
  MABOS: "NE District Greater Boston Event",
  CTHAR: "NE District Hartford Event",
  MAREA: "NE District North Shore Event",
  MELEW: "NE District Pine Tree Event",
  NHDUR: "NE District UNH Event",
  RIKIN: "NE District URI Event",
  MAWOR: "NE District WPI Event",
  CTWAT: "NE District Waterbury Event",
  MAWNE: "NE District Western NE Event",
  NECMP: "New England FIRST District Championship",
  NYNY: "New York City Regional",
  NYTR: "New York Tech Valley Regional",
  MNDU2: "Northern Lights Regional",
  ONSCA: "ONT District Centennial College Event",
  ONOSH: "ONT District Durham College Event",
  ONBAR: "ONT District Georgian Event",
  ONTOR: "ONT District Humber College Event",
  ONHAM: "ONT District McMaster University",
  ONNEW: "ONT District Newmarket Complex Event",
  ONNOB: "ONT District North Bay Event",
  ONWAT: "ONT District University of Waterloo Event",
  ONWIN: "ONT District Windsor Essex Great Lakes Event",
  OKOK: "Oklahoma Regional",
  CAOC: "Orange County Regional",
  FLOR: "Orlando Regional",
  GAALB: "PCH District Albany Event",
  SCAND: "PCH District Anderson Event presented by MAGNA",
  GACAR: "PCH District Carrollton Event",
  SCCHA:
    "PCH District Charleston Event presented by South Carolina Department of Education",
  GADAL: "PCH District Dalton Event",
  GAGWI: "PCH District Gwinnett Event",
  WAAHS: "PNW District Auburn Event",
  WABON: "PNW District Bonney Lake Event",
  ORORE: "PNW District Clackamas Academy Event",
  WASNO: "PNW District Glacier Peak Event",
  ORSAL: "PNW District Oregon State Fairgrounds Event",
  WASAM: "PNW District Sammamish Event",
  WAYAK: "PNW District SunDome Event",
  ORWIL: "PNW District Wilsonville Event",
  PNCMP: "Pacific Northwest FIRST District Championship",
  GACMP: "Peachtree District Championship",
  MXPU: "Regional Hermosillo presented by PrepaTec",
  MXTO: "Regional Laguna presented by Peñoles",
  MXMO: "Regional Monterrey presented by PrepaTec",
  ALHU: "Rocket City Regional",
  CADA: "Sacramento Regional",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  WILA: "Seven Rivers Regional",
  CASJ: "Silicon Valley Regional",
  TNKN: "Smoky Mountains Regional",
  FLWP: "South Florida Regional",
  AUSC: "Southern Cross Regional",
  MOSL: "St. Louis Regional",
  FLTA: "Tallahassee Regional",
  UTWV: "Utah Regional",
  CAVE: "Ventura County Regional",
  WIMI: "Wisconsin Regional",
  TUIS: "İstanbul Regional",
  XXCha:
    "2024 Beijing World Robot Contest Championships FRC Program China Offseason Event",
  XXTai: "2024 FRC Taiwan Playoff",
  NHHol: "2024 Governor's Cup",
  AZGle: "Arizona Robotics League Qualifier 1",
  AZGl2: "Arizona Robotics League Qualifier 2",
  OS522: "Arizona Robotics League Qualifier 3",
  OS864: "Arizona Robotics League Qualifier 4",
  OS870: "Arizona Robotics League Qualifier 5",
  NHALT: "Battle Of the Bay",
  MDGle: "Battle o' Baltimore",
  MAWO2: "BattleCry at WPI",
  CAMis: "Beach Blitz presented by the Gene Haas Foundation",
  MITay: "Big Bang! 2024",
  MIBlo: "Bloomfield Girls Robotics Competition",
  VASal: "Blue Ridge Brawl",
  INLaf: "Boiler Bot Battle",
  WABel: "Bordie In Show",
  NJNor: "Brunswick Eruption",
  CTGla: "CT State Championships",
  CAPit: "CalGames",
  CAPle: "Capital City Classic",
  OHCol: "Central Ohio Robotics Invitational",
  IsBah: "Cezeri Robot Ligi Bahçelievler Off-Season",
  ISUmr: "Cezeri Robot Ligi Ümraniye Off-Season",
  VAAle: "Chesapeake Robotics Icebreaker",
  IAWes: "Clash in the Corn",
  MAFal: "Clipper Clash",
  MOLee: "Cow Town Throwdown",
  OhCle: "Curiosity Open",
  MIDe2: "Detroit City Championship 2024",
  NCSp2: "Doyenne Inspiration East",
  NCHi2: "Doyenne Inspiration West",
  XXMac: "Duel Down Under",
  NJCar: "Duel on the Delaware",
  ARCHIMEDES: "FIRST Championship - Archimedes Division",
  CURIE: "FIRST Championship - Curie Division sponsored by Rockwell Automation",
  DALY: "FIRST Championship - Daly Division sponsored by Qualcomm",
  GALILEO: "FIRST Championship - Galileo Division sponsored by BAE Systems",
  HOPPER: "FIRST Championship - Hopper Division sponsored by Molex",
  JOHNSON:
    "FIRST Championship - Johnson Division sponsored by Gene Haas Foundation",
  MILSTEIN: "FIRST Championship - Milstein Division sponsored by PwC",
  NEWTON: "FIRST Championship - Newton Division sponsored by Medtronic",
  OS285: "FIRST Chance",
  TXCMP2: "FIRST In Texas District Championship - Apollo Division",
  TXCMP1: "FIRST In Texas District Championship - Mercury Division",
  ONCMP1: "FIRST Ontario Provincial Championship - Science Division",
  ONCMP2: "FIRST Ontario Provincial Championship - Technology Division",
  MICMP4: "FIRST in Michigan State Championship - Aptiv Division",
  MICMP3: "FIRST in Michigan State Championship - Consumers Energy Division",
  MICMP1:
    "FIRST in Michigan State Championship - DTE Energy Foundation Division",
  MICMP2:
    "FIRST in Michigan State Championship - Hemlock Semiconductor Division",
  MIBig: "Ferris State Roboday 2024",
  XXCui: "Festival SESI de Robótica - Etapa Off Season - Mato Grosso",
  GADa2: "GRITS 2024",
  MOSt: "Gateway Robotics Challenge (GRC)",
  NHAL2: "Girls Behind the Glass",
  ORWi2: "Girls' Gen Oregon",
  MNDul: "Gitchi Gummi Get-Together",
  MIBr2: "Goonettes Invitational - Day 1",
  OS821: "Goonettes Invitational - Day 2",
  MIWyo: "Grand Rapids Girls Robotics Competition",
  MIMi2: "Great Lakes Bay Bot Bash",
  NYDix: "Half Hollow Hills Robotics Invitational",
  PaHor: "Havoc",
  INInd: "Indiana Robotics Invitational",
  XXHad: "Israeli Off-Season #1",
  XXHa2: "Israeli Off-season #2",
  CODen: "Kendrick Castillo Memorial Tournament (KCMT)",
  MIFli: "Kettering Kickoff (Day 1)",
  MIFl2: "Kettering Kickoff (Day 2)",
  WIEau: "Leviathans Robotics Rampage",
  MONor: "MO-KAN State Championship",
  XXMel: "Melbourne Robotics Tournament (MRT 2024)",
  GTLeo: "Mexico ALL-STAR",
  MIDun: "Michigan Advanced Robotics Competition 2024",
  MNRos: "Minnesota Robotics Invitational",
  MNMin: "Minnesota State High School League Championship",
  WIMuk: "Mukwonago Robotics Offseason Competition",
  TXRo2: "NTX Tournament of Robots",
  NYBro: "NYC Robo Replay",
  NECMP1: "New England FIRST District Championship - Ganson Division",
  NECMP2: "New England FIRST District Championship - Richardson Division",
  MABil: "New England Robotics Derby",
  PACra: "North Catholic Robotics Competition",
  LAMan: "Northshore Knockout",
  OS752: "Overtime Sunday",
  ARSea: "Ozark Mountain Brawl",
  WAMil: "PNW Block Party",
  FLHom: "Pirate's Pillage",
  NYRoc: "Rah Cha Cha Ruckus",
  PAAmb: "Ramp Riot XXIV",
  KYLou: "RiverBOaT Rumble",
  NHMan: "RiverRage 27",
  NYBal: "Robot Rumble",
  NJBri: "Robot Rumble at RoboCon New Jersey",
  ILTre: "Roboteer Rumble 2024",
  XXTor: "Robots@CNE",
  ILRoc: "Rock River Robotics Off-Season Competition",
  TXAus: "Round Rock Robot Rodeo",
  VAGl2: "Rumble #9 With Cheese",
  SCSum: "SCRAP",
  SCCol: "SCRIW XIII",
  TXRoc: "STEM Gals",
  ONHa2: "STEMley Cup Championship",
  AZTem: "Sanghi Foundation AZ State 2024 FIRST Robotics Competition",
  WAVan: "Storm Surge",
  MEFal: "Summer Heat",
  CAAla: "Sunset Showdown",
  NCSpr: "THOR East",
  NCHic: "THOR West",
  WIMus: "TWIST",
  TNKno: "Tennessee Valley Fair Robo-Rodeo 2024",
  TXHo2: "Texas Robotics Invitational",
  LADen: "The Dow Red Stick Rumble",
  TXThe: "The Remix",
  WVFai: "WVROX",
  WAMap: "Washington Girl’s Generation",
  WEEK0: "Week 0",
  MIZee: "West Michigan Robotics Invitational",
};

// Update before closing season. Remove Practice matches if included.
const events2025 = {
  CAAV: "Aerospace Valley Regional",
  TUAK: "Ankara Regional",
  AZGL: "Arizona East Regional",
  AZFG: "Arizona North Regional",
  ARLI: "Arkansas Regional",
  LAKE: "Bayou Regional",
  TUIS2: "Bosphorus Regional",
  OHCL: "Buckeye Regional",
  VAALE:
    "CHS District Alexandria VA Event presented by US Department of Energy",
  MDBET: "CHS District Bethesda MD Event presented by Capital One",
  VABLA: "CHS District Blacksburg VA Event presented by Volvo",
  VAGLE: "CHS District Glen Allen VA Event presented by Dominion Energy",
  MDPAS: "CHS District Pasadena MD Event presented by Ball Corp",
  VAPOR: "CHS District Portsmouth VA Event presented by Newport News Shipyard",
  MDSEV: "CHS District Severn MD Event",
  BCVI: "Canadian Pacific Regional",
  ILPE: "Central Illinois Regional",
  MOSE: "Central Missouri Regional",
  CAFR: "Central Valley Regional",
  CODE: "Colorado Regional",
  CAPT: "Contra Costa Regional",
  CABE: "East Bay Regional",
  MIBAT: "FIM District Battle Creek Event",
  MIBEL: "FIM District Belleville Event",
  MIBER: "FIM District Berrien Springs Event",
  MIESC: "FIM District Escanaba Event",
  MiFER: "FIM District Ferndale Event",
  MIBIG: "FIM District Ferris State Event",
  MIBKN: "FIM District Jackson at Columbia Event",
  MIKEN: "FIM District Kentwood Event",
  MIKET: "FIM District Kettering University Event #1",
  MIKE2: "FIM District Kettering University Event #2",
  MILSU: "FIM District LSSU Event",
  MILAC: "FIM District Lake City Event",
  MILIV: "FIM District Livonia Event",
  MIMCC: "FIM District Macomb Event",
  MIMAS: "FIM District Mason Event",
  MIMID: "FIM District Midland Event",
  MIMIL: "FIM District Milford Event",
  MIMTP: "FIM District Mt Pleasant Event",
  MIMUS: "FIM District Muskegon Event",
  MIDET: "FIM District Renaissance Event",
  MISAL: "FIM District Saline Event",
  MISJO: "FIM District St. Joseph Event",
  MITVC: "FIM District Traverse City Event",
  MITRY: "FIM District Troy Event",
  MIDTR: "FIM District Wayne State Event",
  MIWMI: "FIM District West Michigan Event",
  MIBRO: "FIM District Woodhaven Event",
  INKOK: "FIN District Kokomo Event",
  INLAF: "FIN District Lafayette Event",
  INMIS: "FIN District Mishawaka Event",
  INWAS:
    "FIN District Washington Event presented by Toyota Indiana and Vincennes University",
  ARCHIMEDES: "FIRST Championship - Archimedes Division",
  CURIE: "FIRST Championship - Curie Division",
  DALY: "FIRST Championship - Daly Division",
  CMPTX: "FIRST Championship - FIRST Robotics Competition",
  GALILEO: "FIRST Championship - Galileo Division",
  HOPPER: "FIRST Championship - Hopper Division",
  JOHNSON: "FIRST Championship - Johnson Division",
  MILSTEIN: "FIRST Championship - Milstein Division",
  NEWTON: "FIRST Championship - Newton Division",
  CHCMP: "FIRST Chesapeake District Championship",
  TXCMP: "FIRST In Texas District Championship",
  TXCMP2: "FIRST In Texas District Championship - Apollo Division",
  TXCMP1: "FIRST In Texas District Championship - Mercury Division",
  INCMP: "FIRST Indiana State Championship",
  ISCMP: "FIRST Israel District Championship",
  NYLI2: "FIRST Long Island Regional",
  MRCMP: "FIRST Mid-Atlantic District Championship",
  NCCMP: "FIRST North Carolina State Championship",
  ONCMP: "FIRST Ontario Provincial Championship",
  ONCMP1: "FIRST Ontario Provincial Championship - Science Division",
  ONCMP2: "FIRST Ontario Provincial Championship - Technology Division",
  SCCMP: "FIRST South Carolina State Championship presented by Magna",
  MICMP: "FIRST in Michigan State Championship",
  MICMP4: "FIRST in Michigan State Championship - Aptiv Division",
  MICMP3: "FIRST in Michigan State Championship - Consumers Energy Division",
  MICMP1:
    "FIRST in Michigan State Championship - DTE Energy Foundation Division",
  MICMP2:
    "FIRST in Michigan State Championship - Hemlock Semiconductor Division",
  TXAMA: "FIT District Amarillo Event",
  TXBEL: "FIT District Belton Event",
  TXFOR: "FIT District Fort Worth Event",
  TXHOU: "FIT District Houston Event",
  TXMAN: "FIT District Manor Event",
  TXPLA: "FIT District Plano Event",
  TXSAN: "FIT District San Antonio Event",
  TXCLE: "FIT District Space City @ Friendswood Event",
  TXTOM: "FIT District Tomball Event",
  TXVIC: "FIT District Victoria Event",
  TXWAC: "FIT District Waco Event",
  PABEN: "FMA District Bensalem Event",
  PAWAR: "FMA District Centennial Event",
  PAHAT: "FMA District Hatboro-Horsham Event",
  NJSKI: "FMA District Montgomery Event",
  NJFLA: "FMA District Mount Olive Event",
  NJROB: "FMA District Robbinsville Event",
  NJTAB: "FMA District Seneca Event",
  PAPHI: "FMA District Springside Chestnut Hill Event",
  NJWAS: "FMA District Warren Hills Event",
  NCCAT: "FNC District Catawba County Event",
  NCMEC: "FNC District Mecklenburg County Event",
  NCGRE: "FNC District Pitt County Event",
  NCASH: "FNC District UNC Asheville Event",
  NCPEM: "FNC District UNC Pembroke Event",
  NCWAK: "FNC District Wake County Event",
  SCHAR: "FSC District Hartsville Event",
  SCCHA:
    "FSC District North Charleston Event presented by South Carolina Department of Education",
  QCMO: "Festival de Robotique Regional",
  NYRO: "Finger Lakes Regional",
  NDGF: "Great Northern Regional",
  MOKC: "Greater Kansas City Regional",
  PACA: "Greater Pittsburgh Regional",
  OKTU: "Green Country Regional",
  TUHC: "Haliç Regional",
  HIHO: "Hawaii Regional",
  KSLA: "Heartland Regional",
  NYSU: "Hudson Valley Regional",
  CAPH: "Hueneme Port Regional",
  ISDE1: "ISR District Event #1",
  ISDE2: "ISR District Event #2",
  ISDE3: "ISR District Event #3",
  ISDE4: "ISR District Event #4",
  IDBO: "Idaho Regional",
  IACF: "Iowa Regional",
  MNDU: "Lake Superior Regional",
  NVLV: "Las Vegas Regional",
  CALA: "Los Angeles Regional",
  MSLR: "Magnolia Regional",
  TUIS3: "Marmara Regional",
  OHMV: "Miami Valley Regional",
  ILCH: "Midwest Regional",
  MNMI: "Minnesota 10,000 Lakes Regional",
  MNMI2: "Minnesota Granite City Regional",
  MNUM: "Minnesota North Star Regional",
  NHSAL: "NE District Granite State Event",
  MABOS: "NE District Greater Boston Event",
  CTHAR: "NE District Hartford Event",
  MELEW: "NE District Pine Tree Event",
  MEFAL: "NE District Southern Maine Event",
  NHDUR: "NE District UNH Event",
  RIKIN: "NE District URI Event",
  VTBUR: "NE District UVM Event",
  MAWOR: "NE District WPI Event",
  CTWAT: "NE District Waterbury Event",
  MAWNE: "NE District Western NE Event",
  NECMP: "New England FIRST District Championship",
  NECMP1: "New England FIRST District Championship - Ganson Division",
  NECMP2: "New England FIRST District Championship - Richardson Division",
  NTWC: "New Taipei City Regional",
  NYNY: "New York City Regional",
  NYTR: "New York Tech Valley Regional",
  MNDU2: "Northern Lights Regional",
  OFFLINE: "OFFLINE",
  ONSCA: "ONT District Centennial College Event",
  ONOSH: "ONT District Durham College Event",
  ONTOR: "ONT District Humber Polytechnic Event",
  ONHAM: "ONT District McMaster University",
  ONNEW: "ONT District Newmarket Event",
  ONWEL: "ONT District Niagara College Event",
  ONNOB: "ONT District North Bay Event",
  ONWAT: "ONT District University of Waterloo Event",
  ONWIN: "ONT District Windsor Essex Great Lake Event",
  OKOK: "Oklahoma Regional",
  CAOC: "Orange County Regional",
  FLOR: "Orlando Regional",
  GAALB: "PCH District Albany Event presented by Procter & Gamble",
  GADAL: "PCH District Dalton Event presented by Shaw Industries",
  GAGAI: "PCH District Gainesville Event",
  GAGWI:
    "PCH District Gwinnett Event presented by Gwinnett County School System",
  GASTA: "PCH District Statesboro Event",
  WAAHS: "PNW District Auburn Event",
  WABON: "PNW District Bonney Lake Event",
  ORORE: "PNW District Clackamas Academy Event",
  WASNO: "PNW District Glacier Peak Event",
  ORSAL: "PNW District Oregon State Fair Event",
  WASAM: "PNW District Sammamish Event",
  WAYAK: "PNW District SunDome Event",
  ORWIL: "PNW District Wilsonville Event",
  PNCMP: "Pacific Northwest FIRST District Championship",
  GACMP: "Peachtree District Championship presented by Mercer University",
  WIMU: "Phantom Lakes Regional",
  CAMB: "Pinnacles Regional",
  PRACTICE1: "Practice Event Phase 1",
  PRACTICE2: "Practice Event Phase 2",
  PRACTICE3: "Practice Event Phase 3",
  PRACTICE4: "Practice Event Phase 4",
  BRBA: "Regional Brazil - Brasilia",
  BRSP: "Regional Brazil - São Paulo",
  MXTO: "Regional Laguna presented by Peñoles",
  MXLE: "Regional Leon presented by PrepaTec",
  MXMO: "Regional Monterrey presented by PrepaTec",
  ALHU: "Rocket City Regional",
  CADA: "Sacramento Regional",
  CASD: "San Diego Regional presented by Qualcomm",
  CASF: "San Francisco Regional",
  WILA: "Seven Rivers Regional",
  CNSH: "Shanghai Regional",
  CASJ: "Silicon Valley Regional",
  TNKN: "Smoky Mountains Regional",
  FLWP: "South Florida Regional",
  AUSC: "Southern Cross Regional",
  MOSL: "St. Louis Regional",
  FLTA: "Tallahassee Regional",
  UTWV: "Utah Regional",
  CAVE: "Ventura County Regional",
  WIMI: "Wisconsin Regional",
  TUIS: "İstanbul Regional",
};

export var eventNames = {
  2017: events2017,
  2018: events2018,
  2019: events2019,
  2020: events2020,
  2021: events2021,
  2022: events2022,
  2023: events2023,
  2024: events2024,
  2025: events2025,
};

export const allianceShortNames = {
  Archimedes: "Arch",
  Tesla: "Tesla",
  Darwin: "Darw",
  Carson: "Cars",
  Daly: "Daly",
  Curie: "Curie",
  Carver: "Carv",
  Galileo: "Gal",
  Hopper: "Hop",
  Newton: "New",
  Roebling: "Roeb",
  Turing: "Tur",
  "DTE Energy": "DTE",
  Dow: "Dow",
  "Dow ": "Dow",
  Ford: "Ford",
  Consumers: "Cons",
};

export const hallOfFame = [
  {
    Year: 2024,
    Impact: 2486,
    Challenge: "Crescendo Houston",
    Winner1: 1690,
    Winner2: 4522,
    Winner3: 9432,
    Winner4: 321,
    Winner5: null,
  },
  {
    Year: 2023,
    Impact: 321,
    Challenge: "Charged Up Houston",
    Winner1: 1323,
    Winner2: 4414,
    Winner3: 4096,
    Winner4: 2609,
    Winner5: null,
  },
  {
    Year: 2022,
    Chairmans: 1629,
    Challenge: "Rapid React Houston",
    Winner1: 1619,
    Winner2: 254,
    Winner3: 3175,
    Winner4: 6672,
    Winner5: null,
  },
  {
    Year: 2021,
    Chairmans: 503,
    Challenge: "Infinite Recharge at Home",
    Winner1: null,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2021,
    Chairmans: 4613,
    Challenge: "Infinite Recharge",
    Winner1: null,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2019,
    Chairmans: 1816,
    Challenge: "Destination: Deep Space Detroit",
    Winner1: 3707,
    Winner2: 217,
    Winner3: 4481,
    Winner4: 1218,
    Winner5: null,
  },
  {
    Year: 2019,
    Chairmans: 1902,
    Challenge: "Destination: Deep Space Houston",
    Winner1: 973,
    Winner2: 1323,
    Winner3: 5026,
    Winner4: 4201,
    Winner5: null,
  },
  {
    Year: 2018,
    Chairmans: 1311,
    Challenge: "PowerUP! Houston",
    Winner1: 254,
    Winner2: 148,
    Winner3: 2976,
    Winner4: 3075,
    Winner5: null,
  },
  {
    Year: 2018,
    Chairmans: 2834,
    Challenge: "PowerUP! Detroit",
    Winner1: 2767,
    Winner2: 27,
    Winner3: 4027,
    Winner4: 2708,
    Winner5: null,
  },
  {
    Year: 2017,
    Chairmans: 3132,
    Challenge: "STEAMworks Houston",
    Winner1: 973,
    Winner2: 1011,
    Winner3: 2928,
    Winner4: 5499,
    Winner5: null,
  },
  {
    Year: 2017,
    Chairmans: 2614,
    Challenge: "STEAMworks St Louis",
    Winner1: 2767,
    Winner2: 254,
    Winner3: 862,
    Winner4: 1676,
    Winner5: null,
  },
  {
    Year: 2017,
    Chairmans: null,
    Challenge: "Festival of Champions",
    Winner1: 2767,
    Winner2: 254,
    Winner3: 862,
    Winner4: 1676,
    Winner5: null,
  },
  {
    Year: 2016,
    Chairmans: 987,
    Challenge: "FIRST Stronghold",
    Winner1: 330,
    Winner2: 2481,
    Winner3: 120,
    Winner4: 1086,
    Winner5: null,
  },
  {
    Year: 2015,
    Chairmans: 597,
    Challenge: "Recycle Rush",
    Winner1: 118,
    Winner2: 1671,
    Winner3: 1678,
    Winner4: 5012,
    Winner5: null,
  },
  {
    Year: 2014,
    Chairmans: 27,
    Challenge: "Aerial Assist",
    Winner1: 254,
    Winner2: 469,
    Winner3: 2848,
    Winner4: 74,
    Winner5: null,
  },
  {
    Year: 2013,
    Chairmans: 1538,
    Challenge: "ULTIMATE ASCENT",
    Winner1: 1241,
    Winner2: 1477,
    Winner3: 610,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2012,
    Chairmans: 1114,
    Challenge: "Rebound Rumble",
    Winner1: 180,
    Winner2: 16,
    Winner3: 25,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2011,
    Chairmans: 359,
    Challenge: "LogoMotion",
    Winner1: 254,
    Winner2: 111,
    Winner3: 973,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2010,
    Chairmans: 341,
    Challenge: "Breakaway!",
    Winner1: 294,
    Winner2: 67,
    Winner3: 177,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2009,
    Chairmans: 236,
    Challenge: "Lunacy",
    Winner1: 111,
    Winner2: 67,
    Winner3: 971,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2008,
    Chairmans: 842,
    Challenge: "FIRST Overdrive",
    Winner1: 1114,
    Winner2: 217,
    Winner3: 148,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2007,
    Chairmans: 365,
    Challenge: "Rack-n-Roll",
    Winner1: 177,
    Winner2: 190,
    Winner3: 987,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2006,
    Chairmans: 111,
    Challenge: "Aim High",
    Winner1: 217,
    Winner2: 296,
    Winner3: 522,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2005,
    Chairmans: 67,
    Challenge: "Triple Play",
    Winner1: 330,
    Winner2: 67,
    Winner3: 503,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2004,
    Chairmans: 254,
    Challenge: "FIRST Frenzy - Raising the Bar",
    Winner1: 71,
    Winner2: 435,
    Winner3: 494,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2003,
    Chairmans: 103,
    Challenge: "Stack Attack",
    Winner1: 65,
    Winner2: 111,
    Winner3: 469,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2002,
    Chairmans: 175,
    Challenge: "Zone Zeal",
    Winner1: 66,
    Winner2: 71,
    Winner3: 173,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 2001,
    Chairmans: 22,
    Challenge: "Diabolical Dynamics",
    Winner1: 71,
    Winner2: 125,
    Winner3: 279,
    Winner4: 294,
    Winner5: 365,
  },
  {
    Year: 2000,
    Chairmans: 16,
    Challenge: "Co-Opertition FIRST",
    Winner1: 25,
    Winner2: 232,
    Winner3: 255,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1999,
    Chairmans: 120,
    Challenge: "Double Trouble",
    Winner1: 1,
    Winner2: 48,
    Winner3: 176,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1998,
    Chairmans: 23,
    Challenge: "Ladder Logic",
    Winner1: 45,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1997,
    Chairmans: 47,
    Challenge: "Toroid Terror",
    Winner1: 71,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1996,
    Chairmans: 144,
    Challenge: "Hexagon Havoc",
    Winner1: 73,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1995,
    Chairmans: 151,
    Challenge: "Ramp-n-Roll",
    Winner1: 100,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1994,
    Chairmans: 191,
    Challenge: "Tower Power ",
    Winner1: 144,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1993,
    Chairmans: 7,
    Challenge: "Rug Rage",
    Winner1: 148,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
  {
    Year: 1992,
    Chairmans: 191,
    Challenge: "Maize Craize",
    Winner1: 126,
    Winner2: null,
    Winner3: null,
    Winner4: null,
    Winner5: null,
  },
];
export const specialAwards = [
  {
    Year: 2021,
    awards: [
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Winner",
        series: 1,
        teamNumber: 5553,
        schoolName: "Notre Dame de Bellegarde",
        fullTeamName:
          "Groupe NOËL/SDRA/Elior/Région Auvergne-Rhône-Alpes/Immobilière Notre Dame de Bellegarde/Lions Club/Crédit Mutuel/JEEDOM/IDRAC Business School/TraceParts/RS Components/Party Lite/Mairie de Collonges au Mont d'Or/MAHP/THALES/HEXIS/BBL GROUPE/ROTARY/Groupe KARDOL/CIC/ELCIA/SOLMUREX&Notre Dame de Bellegarde",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 5553,
        schoolName: "Notre Dame de Bellegarde",
        fullTeamName:
          "Groupe NOËL/SDRA/Elior/Région Auvergne-Rhône-Alpes/Immobilière Notre Dame de Bellegarde/Lions Club/Crédit Mutuel/JEEDOM/IDRAC Business School/TraceParts/RS Components/Party Lite/Mairie de Collonges au Mont d'Or/MAHP/THALES/HEXIS/BBL GROUPE/ROTARY/Groupe KARDOL/CIC/ELCIA/SOLMUREX&Notre Dame de Bellegarde",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 8,
        schoolName: "Palo Alto High School",
        fullTeamName:
          "Apple/Bayer Fund/KLA Foundation/Arm Inc./TE Connectivity/BAE Systems/Intuitive Foundation/Markforged/Robby Technologies/Notion/Kirk’s Steakburgers/Emotiv/Solidworks/Asian Box/D&K Precision Sheet Metal/Dropbox&Palo Alto High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 172,
        schoolName: "Falmouth High School & Gorham High School",
        fullTeamName:
          "Lanco Integrated / Chinburg Management / Falmouth High School / Adobe / Texas Instruments / Robotics Institute of Maine / IDEXX Laboratories / GoFar & Falmouth High School & Gorham High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 900,
        schoolName:
          "North Carolina School of Science and Mathematics & Durham Public Schools & Local Home Schools",
        fullTeamName:
          "NCSSM Foundation/NVIDIA/Analog Devices/Google/VMware Foundation/SMT&North Carolina School of Science and Mathematics&Durham Public Schools&Local Home Schools",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 971,
        schoolName: "Mountain View High School",
        fullTeamName:
          "NASA/NASA Ames Research Center/Google/Abbott Fund/Auris Health/DSS SolidWorks/The Linley Group/Lockheed-Martin/BAE Systems/Mountain View High School PTSA/Markforged Inc./Intuitive Surgical/West Coast Products/Automation Direct/Creative Manufacturing Solutions/Group Manufacturing Services/Plug and Play Datacenters&Mountain View High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 1374,
        schoolName: "OAKVILLE TRAFALGAR HIGH SCHOOL",
        fullTeamName:
          "Halton District School Board - Technological Education (HDSB TE) / Canadian National Exhibition (CNE) / Hatch / Siemens Canada / Sheridan College (Sheridan FIRST Alumni) / Jobmaster Magnets / Tetra Society / Sinn Development &OAKVILLE TRAFALGAR HIGH SCHOOL",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 2554,
        schoolName: "John P Stevens High School",
        fullTeamName:
          "Picatinny Arsenal / BAE Systems / Middlesex County College / Edison Public School District / Sekuler STEM Fund / The Shoppes at Colonial Village & John P Stevens High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 3616,
        schoolName:
          "Southside High School & Thibodaux STEM Magnet Acad & Ovey Comeaux High School & Lafayette High School",
        fullTeamName:
          "Comeaux Academic Enrichment Program/Lafayette Parish School System/ISA Lafayette/IEEE Lafayette/Pugh Family Foundation/CLECO/McGill Precision Waterjet LLC/Automated Production/TRINITY GRAPHX SIGNS & DESIGNS/CAD Solutions/Gordan Technologies LLC/Jack and Stephanie Godwin/Chitimacha Louisiana Open & Thibodaux STEM Magnet Acad & Southside High School & Ovey Comeaux High School & Lafayette High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 3792,
        schoolName: "Family/Community",
        fullTeamName:
          "3M/Boeing/University of Missouri-Columbia, College of Engineering/Crawford's Inc.&Family/Community",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 4253,
        schoolName: "Taipei American School",
        fullTeamName: "Taipei American School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 4586,
        schoolName: "Ironi B Modi'in",
        fullTeamName:
          "Apple/Google/Suzuki/ShaGal Marketing Solutions Ltd./Masaeiot/Dfus Natali/Ofer Falafel/Ledico/Bank Hapoalim/Pet-it/Ituran/The Association for the Elderly in Modi'in/Ministry of Education/Modi'in-Maccabim-Re'ut Municipality&Ironi B Modi'in",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 5406,
        schoolName: "Bishop Ryan Catholic Secondary School",
        fullTeamName:
          "HWCDSB/John Deere Canada/Heritage Green Trust/Arcellor Mittal Dofasco/Jantz Canada&Bishop Ryan Catholic Secondary School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 5940,
        schoolName: "Design Tech High School",
        fullTeamName:
          "Oracle/DDS Solidworks/TSSP/Auris Surgical Robotics Inc./Google/KC Tool&Design Tech High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 6418,
        schoolName: "Family/Community",
        fullTeamName: "Cruise Automation/Apple/BAE&Family/Community",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 6479,
        schoolName: "Corona Del Sol High School",
        fullTeamName:
          "Nikola Motors/Dassault Systèmes SOLIDWORKS Corp./Industrial Metal Supply Co./Copper State Bolt & Nut Company/Professional Plastics/Wishes in Stitches Embroidery and Screen Printing/Corona del Sol Math Department/ABF Printing and Marketing/McCarthy Building Companies, Inc/Tempe Union High School District/Corona del Sol High School/Active Solutions, LLC/AZ Diamondbacks Foundation/Boeing/Port Plastics/Teletech Community Foundation/General Dynamics & Corona Del Sol High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 6652,
        schoolName:
          "Facultad de Ingeniería Mecánica y Eléctrica & Universidad Autonoma de Nuevo León",
        fullTeamName:
          "Softtek/Grainger S.A. de C.V./SATI/Carto Monterrey S.A. de C.V./Atlas Management S.A. de C.V./Jetro Systemic S.A. de C.V.&Universidad Autonoma de Nuevo León&Facultad de Ingeniería Mecánica y Eléctrica",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 7480,
        schoolName: "Family/Community",
        fullTeamName:
          "2019 Argosy Foundation FRC Rookie Grant/Novelis Inc./FIRST Canada & Family/Community",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 7504,
        schoolName: "Brewster High School",
        fullTeamName:
          "Collins Aerospace/NASA/Danbury Walmart&Brewster High School",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 7563,
        schoolName: "SESI",
        fullTeamName: "SESI",
        person: null,
      },
      {
        awardId: null,
        teamId: null,
        eventId: null,
        eventDivisionId: null,
        eventCode: "INNCE",
        name: "Innovation Challenge Finalist",
        series: 1,
        teamNumber: 8621,
        schoolName: "Family/Community",
        fullTeamName: "Family/Community",
        person: null,
      },
    ],
  },
];

export const communityUpdateTemplate = {
  nameShortLocal: "",
  cityStateLocal: "",
  topSponsorsLocal: "",
  topSponsorLocal: "",
  sponsorsLocal: "",
  organizationLocal: "",
  robotNameLocal: "",
  awardsLocal: "",
  teamMottoLocal: "",
  teamNotesLocal: "",
  teamYearsNoCompeteLocal: "",
  showRobotName: "",
  teamNotes: "",
  sayNumber: "",
  awardsTextLocal: "",
  lastUpdate: "",
  source: "",
};

export const originalAndSustaining = [
  "20",
  "45",
  "126",
  "148",
  "151",
  "157",
  "190",
  "191",
  "250",
];
export const allianceSelectionOrderBase = [
  { number: 1, round: 1 },
  { number: 2, round: 1 },
  { number: 3, round: 1 },
  { number: 4, round: 1 },
  { number: 5, round: 1 },
  { number: 6, round: 1 },
  { number: 7, round: 1 },
  { number: 8, round: 1 },
  { number: 8, round: 2 },
  { number: 7, round: 2 },
  { number: 6, round: 2 },
  { number: 5, round: 2 },
  { number: 4, round: 2 },
  { number: 3, round: 2 },
  { number: 2, round: 2 },
  { number: 1, round: 2 },
  { number: 1, round: 3 },
  { number: 2, round: 3 },
  { number: 3, round: 3 },
  { number: 4, round: 3 },
  { number: 5, round: 3 },
  { number: 6, round: 3 },
  { number: 7, round: 3 },
  { number: 8, round: 3 },
];

export const allianceSelectionBaseRounds = {
  round1: [1, 2, 3, 4, 5, 6, 7, 8],
  round2: [8, 7, 6, 5, 4, 3, 2, 1],
  round3: [1, 2, 3, 4, 5, 6, 7, 8],
  round4: [8, 7, 6, 5, 4, 3, 2, 1],
};

export const refreshRate = 15; //Refresh rate in seconds for auto update

export const playoffOverrideMenu = [
  { value: 8, label: 8 },
  { value: 7, label: 7 },
  { value: 6, label: 6 },
  { value: 5, label: 5 },
  { value: 4, label: 4 },
];
